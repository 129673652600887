import { Button, Card, CardActions, CardContent, CardMedia, TextField, Typography } from '@material-ui/core'
import '@vidstack/react/player/styles/default/theme.css'
import axios from 'axios'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const LASTFM_APIKEY = '945a859a9ebd7632ae4431538eaeb48a'

export function MusicSearch() {
  const history = useHistory()
  const [search, setSearch] = useState<string>('')
  const [results, setResults] = useState<{
    artist: string
    name: string
    image: string
  }[]>([])
  return (
    <>
      <p>Search album</p>
      <TextField onChange={(e) => setSearch(e.target.value)} />
      <Button onClick={async () => {
        const res = await axios.request({
          method: 'GET',
          url: 'https://ws.audioscrobbler.com/2.0/',
          params: {
            method: 'album.search',
            api_key: LASTFM_APIKEY,
            format: 'json',
            album: search
          }
        })
        setResults(res.data.results.albummatches.album.map((a: any) => ({
          artist: a.artist,
          name: a.name,
          image: a.image.find((i: any) => i.size === 'large')['#text']
        })))
      }}
      >
        Search
      </Button>
      {
        results.map((result, i) => (
          <Card key={i} style={{ maxWidth: 300 }}>
            <CardMedia style={{ height: 140 }} image={result.image} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">{result.name}</Typography>
              <Typography variant="body2">{result.artist}</Typography>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                onClick={async () => {
                  history.push(`/music/${result.artist}/${result.name}`)
                }}
              >
                Prepare
              </Button>
            </CardActions>
          </Card>
        ))
      }
    </>
  )
}
