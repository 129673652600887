import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { iziplaySearch, iziplaySocial, tmdb } from '../tools/api'
import { isObjectMovie } from '../tools/typeGuards'
import './movie.css'

import { stores } from '../tools/store'
import utils from '../tools/utils'

import { Overview } from '../components/Overview'

interface MovieProps {
  match: any
}

type Show = TMDB.Movie | TMDB.Episode

function Movie(props: MovieProps) {
  const { match } = props
  const history = useHistory()
  const [time, setTime] = useState<Iziplay.Time | undefined>()
  const [progress, setProgress] = useState(0)
  const [show, setShow] = useState<Show>({} as Show)
  const [originalName, setOriginalName] = useState('')
  const [cast, setCast] = useState<TMDB.GuestStar[]>([])
  const [similar, setSimilar] = useState<Show[]>([])
  const [external, setExternal] = useState<TMDB.ExternalID>({})
  const [qualities, setQualities] = useState<Iziplay.Downloaded[]>([])
  const [backdrop, setBackdrop] = useState('')
  const [countries, setCountries] = useState<string[]>([])
  const [available, setAvailable] = useState<Iziplay.Downloaded[]>([])

  function isTvShow() {
    return match.params.season && match.params.episode
  }

  function link(nbr: 0 | 1) {
    let linkTab: string[] = []
    if (isTvShow()) {
      linkTab = [
        `tv/${match.params.id}/season/${match.params.season}/episode/${
          match.params.episode
        }?language=${stores.lang.get()}&append_to_response=credits,similar,release_dates`,
        `/search?type=tvshow&providers=[providers]&imdb=${external.imdb_id}&original_title=${originalName}&season=${match.params.season}&episode=${match.params.episode}`
      ]
    } else {
      linkTab = [
        `movie/${
          match.params.id
        }?language=${stores.lang.get()}&append_to_response=credits,similar,release_dates`,
        `/search?type=movie&providers=[providers]&imdb=${
          isObjectMovie(show) && show.imdb_id
        }&name=${isObjectMovie(show) && show.title}&original_title=${
          isObjectMovie(show) && show.original_title
        }`
      ]
    }
    return linkTab[nbr]
  }

  async function fetchSearchIziplay() {
    try {
      const responseSearch = await iziplaySearch().get<{
        available: Iziplay.Downloaded[]
      }>(
        `/downloaded/search${
          !isObjectMovie(show)
            ? `?type=tvshow&imdb=${external.imdb_id}&original_title=${originalName}&season=${match.params.season}&episode=${match.params.episode}`
            : `?type=movie&imdb=${show.imdb_id}&name=${show.title}&original_title=${show.original_title}`
        }`
      )

      setAvailable(oldAvailable => [
        ...oldAvailable,
        ...responseSearch.data.available.filter(
          down => !oldAvailable.find(avail => avail.id === down.id)
        )
      ])

      const providers = Object.keys(stores.settings.get().providers).filter(provider =>
        Boolean(stores.settings.get().providers[provider])
      )

      const promiseProviders = providers.map(async provider => {
        const searchLink = !isObjectMovie(show)
          ? `/search?type=tvshow&providers=${provider}&imdb=${external.imdb_id}&original_title=${originalName}&season=${match.params.season}&episode=${match.params.episode}`
          : `/search?type=movie&providers=${provider}&imdb=${show.imdb_id}&name=${show.title}&original_title=${show.original_title}`

        return iziplaySearch().get<Iziplay.Downloaded[]>(searchLink)
      })

      promiseProviders.forEach(async promiseProv => {
        const fetchProvider = await promiseProv
        const data = fetchProvider.data || []

        setProgress(oldProgress => oldProgress + 1 / providers.length)

        setAvailable(oldAvailable => [
          ...oldAvailable,
          ...data.filter(
            down => down.downloaded && !oldAvailable.find(avail => avail.id === down.id)
          )
        ])

        setQualities(oldQualities => {
          const newQualities = [...oldQualities, ...data]

          return newQualities
            .sort((a, b) => a.priority - b.priority)
            .filter(
              (qualityToFind, index) =>
                newQualities.findIndex(
                  qualityToCompare => qualityToCompare.id === qualityToFind.id
                ) === index
            )
        })
      })
    } catch (e) {
      console.log(e)
    }
  }

  async function fetchShow() {
    try {
      if (isTvShow()) {
        const url = `tv/${match.params.id}`
        const response = await tmdb.get<TMDB.TvShow>(url)

        setBackdrop(response.data.backdrop_path || '')
        setOriginalName(
          utils.isJapaneseCharacters(response.data.original_name)
            ? response.data.name
            : response.data.original_name
        )

        stores.currentPath.setCurrentPath([
          response.data.original_name,
          `Season ${match.params.season}`,
          `Episode ${match.params.episode}`
        ])

        const responseExternal = await tmdb.get<TMDB.ExternalID>(
          `tv/${match.params.id}/external_ids`
        )
        setExternal(responseExternal.data)

        const responseSocial = await iziplaySocial()
          .get(
            `/time/${responseExternal.data.imdb_id}/${match.params.season}/${match.params.episode}`
          )
          .catch(_ => console.log('You never saw this film'))

        if (responseSocial) {
          setTime(responseSocial.data.data)
        }
      } else {
        stores.currentPath.setCurrentPath([])
      }

      const responseShow = await tmdb.get<TMDB.Movie>(link(0))

      if (isObjectMovie(responseShow.data)) {
        responseShow.data.durate = utils.transformDurate(responseShow.data.runtime ?? 0)
      }

      setShow(responseShow.data)
      if (isObjectMovie(responseShow.data) && responseShow.data.credits) {
        setCast(responseShow.data.credits.cast.slice(0, 6))
      }
      if (isObjectMovie(responseShow.data)) {
        setSimilar(responseShow.data.similar ? responseShow.data.similar.results : [])
      }

      if (isObjectMovie(responseShow.data)) {
        const responseSocial = await iziplaySocial()
          .get(`/time/${responseShow.data.imdb_id}`)
          .catch(_ => console.log('You never saw this film'))

        if (responseSocial) {
          setTime(responseSocial.data.data)
        }
      }

      // @ts-ignore
      if (window.cast && !isTvShow()) {
        // @ts-ignore
        const session = window.cast.framework.CastContext.getInstance().getCurrentSession()
        if (session) {
          console.log('sending to chromecast infos')
          session.sendMessage('urn:x-cast:tv.iziplay.chromecast', {
            type: 'movie',
            data: {
              title: responseShow.data.title,
              backdrop_path: responseShow.data.backdrop_path,
              overview: responseShow.data.overview
            }
          })
        }
      }

      if (!isObjectMovie(responseShow.data) || !responseShow.data.release_dates) return

      const localCountries = responseShow.data.release_dates.results.reduce<string[]>(
        (accum, country) => {
          const listCode = country.release_dates
            .map(date => (date.type >= 3 ? country.iso_3166_1 : ''))
            .filter(Boolean)

          return [...accum, ...listCode]
        },
        []
      )

      if (localCountries.length === 0 && !isTvShow()) {
        stores.notification.displayNotification(
          "This film didn't left theaters yet, you won't find it in a good quality",
          'error'
        )
      }

      setCountries(
        localCountries.filter((country, index) => localCountries.indexOf(country) === index).sort()
      )
    } catch (e) {
      console.log(e)
      stores.notification.displayNotification('Cannot reach TMDB to get infos', 'error')
    }
  }

  function clickQuality(quality: Iziplay.Downloaded) {
    if (quality && Object.keys(quality).length > 0) {
      const url = isObjectMovie(show)
        ? `/hls/${quality.id}?imdb=${show.imdb_id}&tmdb=${match.params.id}`
        : `/hls/${quality.id}?imdb=${external.imdb_id}&tvdb=${external.tvdb_id}&tmdb=${match.params.id}&tmdb_episode=${show.id}&season=${match.params.season}&episode=${match.params.episode}`
      history.push(url)

      return stores.notification.displayNotification(
        'Download in progress, please wait...',
        'success'
      )
    }
  }

  useEffect(() => {
    setAvailable([])
    setQualities([])
    setProgress(0)
    setShow({} as Show)
    fetchShow()
  }, [match.params.id])

  useEffect(() => {
    console.log(originalName)
    console.log(show)

    if (
      ((isTvShow() && originalName) || (isObjectMovie(show) && show.title)) &&
      (!isTvShow() || Object.keys(external).length > 0)
    ) {
      fetchSearchIziplay()
    }
  }, [originalName, show])

  return (
    <Overview
      type={isTvShow() ? 'tvshow' : 'movie'}
      progress={progress}
      qualities={qualities}
      available={available}
      time={time}
      backdrop={backdrop}
      casts={cast}
      similar={similar}
      show={show}
      countries={countries}
      clickQuality={clickQuality}
    />
  )
}

export default Movie
