import { useEffect, useState, ChangeEvent, KeyboardEvent } from 'react'
import { iziplayAuth } from '../tools/api'
import { useHistory } from 'react-router-dom'

import './login.css'

function Login() {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (global.localStorage.getItem('token')) {
      history.push('/movies')
    }
  }, [])

  function handleMail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value)
  }

  function handlePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value)
  }

  async function signin() {
    const res = await iziplayAuth()
      .post<{
        token: Iziplay.Token
      }>('/signin/', {
        mail: email,
        password
      })
      .catch(() => {
        setError('Wrong mail or password')
        setPassword('')
      })

    if (!res) {
      return
    }

    setError('')
    global.localStorage.setItem('token', res.data.token)
    history.push('/movies')
  }

  function handleKey(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      signin()
    }
  }

  return (
    <div className="login">
      <div className="center">
        <div>
          <img src="/img/logo.svg" alt="iziplay" />
        </div>
        <div className="error">{error}</div>
        <div>
          <input
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleMail}
            onKeyPress={handleKey}
          />
        </div>
        <div>
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePassword}
            onKeyPress={handleKey}
          />
        </div>
        <div>
          <button onClick={signin}>Sign in</button>
        </div>
      </div>
    </div>
  )
}

export default Login
