import { useEffect } from 'react'

import { observer } from 'mobx-react'
import { stores } from '../tools/store'

import Grid from '../components/grid'

function TvtimeTowatch() {
  useEffect(() => {
    if (stores.tvtimetowatch.get().length === 0) {
      stores.tvtimetowatch.fetchTvtimetowatch()
    }
    stores.currentPath.setCurrentPath([])
  }, [])

  function scroll() {
    stores.myhistoryPage.nextMyhistoryPage()
    stores.myhistory.fetchMyhistory()
  }

  return (
    <Grid
      shows={stores.tvtimetowatch.get()}
      infiniteFunc={() => {
        scroll()
      }}
    />
  )
}

export default observer(TvtimeTowatch)
