import { Table } from './table'

import './debug.css'

function Debug() {
  function error() {
    throw new Error('V2 is pastresfort')
  }

  return (
    <div className="debug">
      <h1>Environment</h1>
      <Table data={process.env} />
      <h1>Local storage</h1>
      <Table data={localStorage} />
      <h1>Cookie</h1>
      {document.cookie}
      <button onClick={error}>Throw error</button>
    </div>
  )
}

export default Debug
