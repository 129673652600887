import { AxiosError, isAxiosError } from 'axios'
import utils from '../tools/utils'

export function isStringTheme(theme: string): theme is Iziplay.Theme {
  const themeList = utils.themes

  return themeList.includes(theme)
}

export function isStringLanguage(language: string): language is Iziplay.Lang {
  const langList = utils.langs

  return langList.includes(language)
}

export function isStringQuality(quality: string): quality is Iziplay.Downloaded['quality'] {
  const qualityList = ['4K', '2160p', '1080p', '720p', '480p', 'CAM', '3D']

  return qualityList.includes(quality)
}

export function isObjectPerson(data: any): data is TMDB.Person {
  return 'gender' in data
}

export function isObjectDownloaded(data: any): data is Iziplay.Downloaded {
  return 'magnet' in data || 'server' in data
}

export function isObjectMovie(data: any): data is TMDB.Movie {
  return 'title' in data
}

export function isErrorResponse(error: unknown): error is AxiosError<{ err: string }> {
  return isAxiosError(error) && error.response?.data && 'err' in error.response.data
}
