import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { iziplaySocial, tmdb } from '../tools/api'

import Show from '../components/show'
import { stores } from '../tools/store'
import utils from '../tools/utils'

type Params = {
  id: string
  season: string
}

export function Season() {
  const params = useParams<Params>()
  const lang = stores.lang.get()

  const [season, setSeason] = useState<TMDB.Season>()
  const [watched, setWatched] = useState<Iziplay.Time[]>([])

  async function getSeason() {
    const response = await tmdb.get(`tv/${params.id}/season/${params.season}?language=${lang}`)
    setSeason(response.data)

    const show = await tmdb.get<TMDB.TvShow & { external_ids: TMDB.ExternalID }>(
      `tv/${params.id}?language=${lang}&append_to_response=external_ids`
    )
    const showName = utils.isJapaneseCharacters(show.data.original_name)
      ? show.data.name
      : show.data.original_name

    stores.currentPath.setCurrentPath([showName, `Season ${params.season}`])

    try {
      const w = await iziplaySocial().get<{ data: Iziplay.Time[] }>(
        `/time/${show.data.external_ids.imdb_id}/${params.season}`
      )
      setWatched(w.data.data)
    } catch (e) {}
  }

  useEffect(() => {
    getSeason()
  }, [])

  if (!season) {
    return <p>Loading...</p>
  }

  return (
    <div className="grid scrollable">
      {season?.episodes.map(ep => (
        <Show
          key={`${params.id}_${ep.season_number}_${ep.episode_number}`}
          details={ep}
          url={`/tvshows/${params.id}/${ep.season_number}/${ep.episode_number}`}
          watched={watched?.find(w => w.episode === ep.episode_number)}
        />
      ))}
    </div>
  )
}
