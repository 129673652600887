import './spinner.css'

function Spinner() {
  return (
    <div className="spinner_movie">
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  )
}

export default Spinner
