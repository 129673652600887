import { useEffect, useState } from 'react'
import NProgress from 'nprogress'
import { iziplaySupport } from '../tools/api'
import { Question } from '../components/question'
import { Ticket } from '../components/ticket'
import './support.css'

import { stores } from '../tools/store'

import FAQ from '../tools/faq.json'

function Support() {
  const [subject, setSubject] = useState('')
  const [text, setText] = useState('')
  const [myTickets, setMyTickets] = useState<Iziplay.Ticket[]>([])

  function getUserTickets() {
    NProgress.start()
    iziplaySupport()
      .get('')
      .then(response => {
        NProgress.inc()
        NProgress.done()

        if (response.data) {
          setMyTickets(response.data)
        }
      })
      .catch(_ => {
        NProgress.done()
      })
  }

  function sendTickets() {
    NProgress.start()
    iziplaySupport()
      .post('', {
        subject,
        text
      })
      .then(() => {
        NProgress.inc()
        setSubject('')
        setText('')
        getUserTickets()
        NProgress.done()
        return stores.notification.displayNotification('Ticket sent, thank you!', 'success')
      })
      .catch(err => {
        console.log(err.response)
        NProgress.done()
        return stores.notification.displayNotification('Error has occured', 'error')
      })
  }

  useEffect(() => {
    getUserTickets()
    stores.currentPath.setCurrentPath([])
  }, [])

  const faqs = FAQ.fr.map(elem => (
    <Question key={elem.question} quest={elem.question} resp={elem.response} />
  ))

  return (
    <div className="support scrollable">
      <div className="box">
        <div className="title">FAQ</div>
        {faqs}
      </div>

      <div className="box">
        <div className="title">My tickets</div>
        <div className="element ticketElement">
          {myTickets.length === 0 ? (
            <p>No ticket</p>
          ) : (
            myTickets.map(elem => (
              <Ticket
                key={elem.issue_number}
                title={elem.title}
                date={new Date(elem.date).toLocaleString()}
                text={elem.body}
                closed={elem.closed}
              />
            ))
          )}
        </div>
      </div>

      <div className="box">
        <div className="title">Tickets</div>
        <div className="element">
          <input
            placeholder="Title of issue"
            className="ticketText"
            type="text"
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />
          <textarea
            placeholder="Describe your issue here..."
            className="ticketText"
            rows={8}
            value={text}
            onChange={e => setText(e.target.value)}
          />
          <button onClick={sendTickets}>Send</button>
        </div>
      </div>
    </div>
  )
}

export default Support
