import { makeAutoObservable, configure } from 'mobx'
import axios, { AxiosResponse, isAxiosError } from 'axios'
import async from 'async'
import { tmdb, iziplayAuth, iziplaySearch, iziplaySocial } from './api'
import NProgress from 'nprogress'
import { createTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { isErrorResponse } from './typeGuards'

configure({
  enforceActions: 'never'
})

export interface ObservableStoreProps {
  lang: Iziplay.Lang
  moviesPage: number
  movies: TMDB.Movie[]
  downloadedPage: number
  downloaded: Iziplay.Downloaded[]
  myhistoryPage: number
  myhistory: Iziplay.MyHistory[]
  tvshows: TMDB.TvShow[]
  tvshowsPage: number
  tvtimetowatch: any[]
  currentPath: string[]

  mobileSidebar: boolean
  privateMode: boolean

  user: {
    username: string
    mailHash: string
    role: -1 | 0 | 1
    tvTime?: string
    trakt?: string
  }
  settings: {
    [x: string]: any
  }
  theme: Iziplay.Theme
  muiTheme: Theme
  notification: {
    message: string
    type: 'success' | 'error' | 'info'
    color: string
    classes: string
  }
  ws: any
}

export function observableStore(defaultDatas: ObservableStoreProps) {
  const {
    moviesPage,
    movies,
    downloadedPage,
    downloaded,
    myhistoryPage,
    myhistory,
    tvshowsPage,
    tvshows,
    tvtimetowatch,
    currentPath,
    privateMode,
    mobileSidebar,
    lang,
    user,
    theme,
    muiTheme,
    ws,
    notification,
    settings
  } = defaultDatas

  const observableSettings = makeAutoObservable({
    settings,
    get() {
      return this.settings
    },
    sendSetting(sett: keyof ObservableStoreProps['settings']) {
      const obj: any = {}
      obj[sett] = this.settings[sett]
      iziplaySocial().put('/settings', obj)
    }
  })

  const observableLang = makeAutoObservable({
    lang,
    get() {
      return this.lang
    },
    setLang(newLang: ObservableStoreProps['lang']) {
      this.lang = newLang
    }
  })

  const observableUser = makeAutoObservable({
    user,
    get() {
      return this.user
    },
    async fetchUser() {
      try {
        const response = await iziplayAuth().get('/me/')
        this.user = { ...this.user, ...response.data }
      } catch (error) {
        if (isAxiosError(error)) console.log(error.response)
        if (isErrorResponse(error) && error.response?.data.err === 'Invalid token') {
          global.localStorage.removeItem('token')
          global.window.location.href = '/'
          return
        }

        throw error
      }

      const response = await iziplaySocial().get<ObservableStoreProps['settings']>('/settings')
      observableSettings.settings = { ...observableSettings.settings, ...response.data }

      if ('lang' in response.data) {
        observableLang.setLang(response.data.lang)
      }

      const input: any = {}
      const providers = await iziplaySearch().get('/providers')
      providers.data.forEach((prov: any) => {
        input[prov] = observableSettings.settings.providers[prov] !== false
      })
      observableSettings.settings.providers = input
    }
  })

  const observableNotification = makeAutoObservable({
    notification,
    get() {
      return this.notification
    },
    displayNotification(
      message: ObservableStoreProps['notification']['message'],
      type: ObservableStoreProps['notification']['type'],
      timeout: number = 3
    ) {
      let tmpType = type
      const allType = ['success', 'error', 'info']
      const allColor = {
        success: 'rgba(103, 212, 80, 0.9)',
        error: 'rgba(212, 81, 81, 0.9)',
        info: 'rgba(52, 152, 219, 0.9)'
      }

      if (!tmpType || allType.indexOf(tmpType) === -1) {
        tmpType = 'success'
      }

      this.notification.message = message
      this.notification.type = tmpType
      this.notification.color = allColor[tmpType]
      this.notification.classes = 'notificationBar opacity'

      setTimeout(() => {
        this.notification.classes = 'notificationBar'
      }, 10)

      setTimeout(() => {
        this.notification.classes = 'notificationBar opacity'

        setTimeout(() => {
          this.notification.classes = 'notificationBar opacity hide'
        }, 100)
      }, timeout * 1000)
    }
  })

  function getTmdbInfosViewing(elm: Iziplay.Downloaded | Iziplay.MyHistory, cb: any) {
    const file = elm.id
    if (elm.imdb && elm.imdb !== '' && !elm.imdb.startsWith('tt')) {
      elm.imdb = `tt${elm.imdb}`
    }
    tmdb
      .get(`find/${elm.imdb}?language=${observableLang.lang}&external_source=imdb_id`)
      .then((res: any) => {
        NProgress.inc()
        let obj: any = {}
        const response = res.data?.movie_results?.[0] || res.data?.tv_results?.[0] || {}
        obj = Object.assign(elm, response)
        // Why not push? Because mobx.
        if (elm.season !== null) {
          tmdb
            .get(`tv/${obj.id}/season/${obj.season}/episode/${obj.episode}`)
            .then((tmdbRes: any) => {
              obj = Object.assign(obj, tmdbRes.data)
              obj.file = file
              obj.id_episode = obj.id
              obj.id = response.id
              return cb(obj)
            })
            .catch((err: Error) => {
              console.log(err)
              console.log(obj)
            })
          return
        }
        obj.file = file
        return cb(obj)
      })
      .catch((_: Error) => cb())
  }

  const observableMoviesPage = makeAutoObservable({
    moviesPage,
    get() {
      return this.moviesPage
    },
    nextMoviesPage() {
      this.moviesPage += 1
    }
  })

  const observableMovies = makeAutoObservable({
    movies,
    get() {
      return this.movies
    },
    fetchMovies() {
      NProgress.start()
      const url = `discover/movie?language=${observableLang.get()}&page=${
        observableMoviesPage.moviesPage
      }&sort_by=popularity.desc`
      NProgress.inc()
      tmdb
        .get<{
          page: number
          results: TMDB.Movie[]
          total_pages: number
          total_results: number
        }>(url)
        .then(response => {
          NProgress.inc()
          this.movies = this.movies.concat(response.data.results)
          NProgress.done()
        })
        .catch(() => {
          NProgress.done()
        })
    },
    resetMovies() {
      this.movies = []
      observableMoviesPage.moviesPage = 1
    }
  })

  const observableDownloadedPage = makeAutoObservable({
    downloadedPage,
    get() {
      return this.downloadedPage
    },
    nextDownloadedPage() {
      this.downloadedPage += 1
    }
  })

  const observableDownloaded = makeAutoObservable({
    downloaded,
    get() {
      return this.downloaded
    },
    fetchDownloaded() {
      NProgress.start()
      let concatDownload: Iziplay.Downloaded[] = []
      if (this.downloaded) concatDownload = this.downloaded
      NProgress.inc()
      iziplaySearch()
        .get(`/downloaded?page=${observableDownloadedPage.downloadedPage}`)
        .then(
          (
            response: AxiosResponse<{
              downloaded: Iziplay.Downloaded[]
              total: number
            }>
          ) => {
            NProgress.inc()

            async.eachOf(
              response.data.downloaded,
              (elm, _, eachofcb) => {
                NProgress.inc()
                if (elm.imdb) {
                  getTmdbInfosViewing(elm, (obj: any) => {
                    concatDownload = concatDownload.concat([obj])
                    return eachofcb()
                  })
                } else {
                  return eachofcb()
                }
              },
              err => {
                concatDownload = concatDownload.sort((a, b) => b.downloaded - a.downloaded)
                this.downloaded = concatDownload
                console.log(`imdb get done${err}`)
                NProgress.done()
              }
            )
          }
        )
        .catch((error: Error) => {
          console.log(error)
          NProgress.done()
        })
    },
    resetDownloaded() {
      this.downloaded = []
      observableDownloadedPage.downloadedPage = 1
    }
  })

  const observableMyhistoryPage = makeAutoObservable({
    myhistoryPage,
    get() {
      return this.myhistoryPage
    },
    nextMyhistoryPage() {
      this.myhistoryPage += 1
    }
  })

  const observableMyhistory = makeAutoObservable({
    myhistory,
    get() {
      return this.myhistory
    },
    fetchMyhistory() {
      NProgress.start()
      let concatDownload: Iziplay.MyHistory[] = []
      if (this.myhistory) concatDownload = this.myhistory
      iziplaySocial()
        .get(`/history?page=${observableMyhistoryPage.myhistoryPage}`)
        .then(
          (
            response: AxiosResponse<{
              data: Iziplay.MyHistory[]
              total: number
            }>
          ) => {
            NProgress.inc()

            async.eachOf(
              response.data.data,
              (elm, _, eachofcb) => {
                NProgress.inc()
                if (elm.imdb) {
                  getTmdbInfosViewing(elm, (obj: any) => {
                    concatDownload = concatDownload.concat([obj])
                    return eachofcb()
                  })
                } else {
                  return eachofcb()
                }
              },
              err => {
                concatDownload = concatDownload.sort((a, b) => b.updated - a.updated)
                this.myhistory = concatDownload
                console.log(`imdb get done${err}`)
                NProgress.done()
              }
            )
          }
        )
        .catch((error: Error) => {
          console.log(error)
          NProgress.done()
        })
    },
    resetMyHistory() {
      this.myhistory = []
      observableMyhistoryPage.myhistoryPage = 1
    }
  })

  const observableTvShowsPage = makeAutoObservable({
    tvshowsPage,
    get() {
      return this.tvshowsPage
    },
    nextTvshowsPage() {
      this.tvshowsPage += 1
    }
  })

  const observableTvShows = makeAutoObservable({
    tvshows,
    get() {
      return this.tvshows
    },
    fetchTvshows() {
      NProgress.start()
      const url = `discover/tv?language=${observableLang.lang}&page=${observableTvShowsPage.tvshowsPage}&sort_by=popularity.desc`
      NProgress.inc()
      tmdb
        .get(url)
        .then((response: any) => {
          NProgress.inc()
          this.tvshows = this.tvshows.concat(response.data.results)
          NProgress.done()
        })
        .catch((error: Error) => {
          console.log(error)
          NProgress.done()
        })
    },
    resetTvshows() {
      this.tvshows = []
      observableTvShowsPage.tvshowsPage = 1
    }
  })

  const observableTvtimetowatch = makeAutoObservable({
    tvtimetowatch,
    get() {
      return this.tvtimetowatch
    },
    fetchTvtimetowatch() {
      let concatDownload: any[] = []
      if (this.tvtimetowatch) concatDownload = this.tvtimetowatch
      iziplaySocial()
        .get('/tvtime/to_watch')
        .then((tvtimeResponse: any) => {
          NProgress.inc()

          async.eachOf(
            tvtimeResponse.data.episodes,
            (elm: any, _, eachofcb) => {
              NProgress.inc()
              console.log('req tmdb')
              tmdb
                .get(`find/${elm.show.id}?language=${observableLang.lang}&external_source=tvdb_id`)
                .then((response: any) => {
                  NProgress.inc()
                  const [obj] = response.data.tv_results
                  obj.episode_number = elm.number
                  obj.episode = elm.number
                  obj.season = elm.season_number
                  obj.name = elm.name
                  obj.release_date = elm.air_date
                  obj.url = `/tvshows/${obj.id}/${obj.season}/${obj.episode}`
                  obj.moreDesc = `${
                    elm.show.aired_episodes - elm.show.seen_episodes
                  } episodes to watch`
                  // Why not push? Because mobx.
                  concatDownload = concatDownload.concat([obj])
                  return eachofcb()
                })
                .catch(() => eachofcb())
            },
            err => {
              concatDownload = concatDownload.sort((a, b) => b.updated - a.updated)
              this.tvtimetowatch = concatDownload
              console.log(`imdb get done${err}`)
              NProgress.done()
            }
          )
        })
    }
  })

  const observableCurrentPath = makeAutoObservable({
    currentPath,
    get() {
      return this.currentPath
    },
    setCurrentPath(path: string[]) {
      this.currentPath = path
    }
  })

  const observablePrivateMode = makeAutoObservable({
    privateMode,
    get() {
      return this.privateMode
    },
    setPrivateMode(mode: any) {
      this.privateMode = mode
    }
  })

  const observableMobileSidebar = makeAutoObservable({
    mobileSidebar,
    get() {
      return this.mobileSidebar
    },
    setMobileSidebar(mode: any) {
      this.mobileSidebar = mode
    }
  })

  const observableTheme = makeAutoObservable({
    theme,
    muiTheme,
    get() {
      return this.theme
    },
    saveTheme(newTheme: ObservableStoreProps['theme']) {
      global.localStorage.setItem('theme', newTheme)
      this.theme = newTheme
      this.setTheme(newTheme)
    },
    setTheme(newTheme: ObservableStoreProps['theme']) {
      const { style } = document.documentElement
      const color = global.localStorage.getItem('color')
      if (color) {
        style.setProperty('--purple', color)
      } else {
        style.setProperty('--purple', '#7e94f7')
      }

      style.setProperty('--green', '#2ecc71')
      style.setProperty('--red', '#e74c3c')
      if (newTheme === 'light') {
        style.setProperty('--white', '#ffffff')
        style.setProperty('--dark-white', '#FAFBFF')
        style.setProperty('--light-gray', '#EFF2FA')
        style.setProperty('--black', '#404254')
        this.setMuiTheme('light')
      } else if (newTheme === 'dark') {
        style.setProperty('--white', '#333333')
        style.setProperty('--dark-white', '#303030')
        style.setProperty('--light-gray', '#404040')
        style.setProperty('--black', '#C8C8CC') // Must change one day
        this.setMuiTheme('dark')
      } else if (newTheme === 'auto') {
        axios.get('https://geoip.fruitice.fr/v1').then(geoipRes => {
          axios
            .get(
              `https://api.sunrise-sunset.org/json?lat=${geoipRes.data.latitude}&lng=${geoipRes.data.longitude}&formatted=0`
            )
            .then(res => {
              const sunrise = new Date(res.data.results.sunrise)
              const sunset = new Date(res.data.results.sunset)
              const now = new Date()

              if (now > sunrise && now < sunset) {
                this.setTheme('light')
              } else {
                this.setTheme('dark')
              }

              setTimeout(() => {
                this.setTheme('auto')
              }, 60 * 60 * 1000)
            })
        })
      }
    },
    changeInterfaceColor(color: string) {
      const { style } = document.documentElement
      global.localStorage.setItem('color', color)
      style.setProperty('--purple', color)
      this.setMuiTheme(this.muiTheme.palette.type)
    },
    setMuiTheme(type: 'dark' | 'light') {
      const { style } = document.documentElement

      this.muiTheme = createTheme({
        typography: {
          fontFamily: "'Noto Sans', 'Noto Sans SC', sans-serif"
        },
        palette: {
          type,
          primary: {
            main: style.getPropertyValue('--purple')
          },
          secondary: {
            main: style.getPropertyValue('--green')
          }
        }
      })
    }
  })

  const observableWs = makeAutoObservable({
    ws,
    get() {
      return this.ws
    },
    connectWs() {
      if (
        this.ws &&
        typeof this.ws.readyState !== 'undefined' &&
        this.ws.readyState === this.ws.OPEN
      ) {
        return
      }
      console.log('Connecting to WSS')
      this.ws = new global.WebSocket(
        `${process.env.REACT_APP_IZIPLAY_URL_WS}/?Authorization=Bearer ${global.localStorage.token}`
      )
      this.ws.onopen = () => {
        console.log('WS connected')
      }
      this.ws.onmessage = (msg: any) => {
        try {
          const parsedMsg = JSON.parse(msg.data)

          switch (parsedMsg.action) {
            case 'notification': {
              observableNotification.displayNotification(parsedMsg.data.text, parsedMsg.data.type)
              break
            }
            default:
          }
        } catch (e) {
          console.log(e)
        }
      }
      this.ws.onclose = () => {
        setTimeout(() => {
          this.connectWs()
        }, 2000)
      }
    },
    rtSend(act: string, data: unknown) {
      if (!this.ws) return false
      if (observablePrivateMode.privateMode) return false
      this.ws.send(
        JSON.stringify({
          act,
          data
        })
      )
      return true
    }
  })

  return {
    settings: observableSettings,
    user: observableUser,
    lang: observableLang,
    notification: observableNotification,
    moviesPage: observableMoviesPage,
    movies: observableMovies,
    downloadedPage: observableDownloadedPage,
    downloaded: observableDownloaded,
    myhistoryPage: observableMyhistoryPage,
    myhistory: observableMyhistory,
    tvshows: observableTvShows,
    tvshowsPage: observableTvShowsPage,
    tvtimetowatch: observableTvtimetowatch,

    theme: observableTheme,

    currentPath: observableCurrentPath,
    privateMode: observablePrivateMode,
    mobileSidebar: observableMobileSidebar,

    ws: observableWs
  }
}

export const stores = observableStore({
  user: {
    username: 'John Doe',
    mailHash: '',
    role: -1
  },
  settings: {
    providers: {
      '1337x': false,
      '1337x:j': false,
      'api-fetch': false,
      btdb: false,
      'cpasbienclone:j': false,
      'epizod:j': false,
      'ettv:j': false,
      eztv: false,
      'eztv:j': false,
      'gktorrent:j': false,
      'kickasstorrent-kathow:j': false,
      'kickasstorrent:j': false,
      'oxtorrent:j': false,
      pirateiro: false,
      'pirateiro:j': false,
      rarbg: false,
      'rarbg:j': false,
      'torrent9:j': false,
      'torrent9clone:j': false,
      'torrent-search': false,
      torrentking: false,
      yts: false,
      'yts:j': false
    },
    subtitles: ['fr', 'en'] // French, English, 中文 (Zhōngwén)
  },
  lang: 'en-US',
  notification: {
    message: '',
    type: 'success',
    color: '',
    classes: 'notificationBar opacity hide'
  },
  movies: [],
  moviesPage: 1,

  downloaded: [],
  downloadedPage: 1,

  myhistory: [],
  myhistoryPage: 1,

  tvshows: [],
  tvshowsPage: 1,

  tvtimetowatch: [],

  currentPath: [],
  mobileSidebar: false,
  privateMode: false,

  theme: global.localStorage.theme || 'light',
  muiTheme: createTheme(),

  ws: {}
})
