import './ticket.css'

interface TicketProps {
  title: string
  text: string
  date: string
  closed: boolean
}

export function Ticket(props: TicketProps) {
  const { title, text, date, closed } = props

  return (
    <div className={`ticketBox ${closed ? 'ticketClosed' : 'ticketOpen'}`}>
      <div className="ticketTitle">
        {title} <span style={{ float: 'right' }}>{date}</span>
      </div>
      <div className="ticketBody">{text}</div>
    </div>
  )
}
