import { observer } from 'mobx-react'
import './props.css'

interface PropsProps {
  props: {
    value: string
    name: string
  }[]
}

function Props(props: PropsProps) {
  const { props: properties } = props

  const mappedProps = properties.map(prop => (
    <div key={prop.name}>
      <div>{prop.value}</div>
      <div>
        <img src={`/img/${prop.name}.svg`} alt={prop.name} />
      </div>
    </div>
  ))

  return <div className="props">{mappedProps}</div>
}

export default observer(Props)
