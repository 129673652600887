import { useEffect } from 'react'
import { stores } from '../tools/store'
import { observer } from 'mobx-react'

import Grid from '../components/grid'

function Tvshows() {
  useEffect(() => {
    if (stores.tvshows.get().length === 0) {
      stores.tvshows.fetchTvshows()
    }
    // @ts-ignore
    if (window.cast) {
      // @ts-ignore
      const session = window.cast.framework.CastContext.getInstance().getCurrentSession()
      if (session) {
        session.sendMessage(
          'urn:x-cast:tv.iziplay.chromecast',
          {
            type: 'home'
          },
          () => console.log('good'),
          () => console.log('bad')
        )
      }
    }
    stores.currentPath.setCurrentPath([])
  }, [])

  function scroll() {
    stores.tvshowsPage.nextTvshowsPage()
    stores.tvshows.fetchTvshows()
  }

  return (
    <Grid
      shows={stores.tvshows.get()}
      infiniteFunc={() => {
        scroll()
      }}
    />
  )
}

export default observer(Tvshows)
