import './question.css'

interface QuestionProps {
  quest: string
  resp: string
}

export function Question(props: QuestionProps) {
  const { quest, resp } = props

  return (
    <div className="questionElement">
      <div className="questionTitle">{quest}</div>
      <div className="questionResp">{resp}</div>
    </div>
  )
}
