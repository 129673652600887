import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react'
import { stores } from '../../tools/store'

export interface Artist {
  id: string
  name: string
  releases?: Release[]
}

export interface Release {
  id: string
  name: string
  media?: {
    id: string
    tracks: Track[]
}[]
}

export interface Track {
  id: string
  name: string
  artist: Artist
  release: Release
  medium: number
  position: number
  duration: number
  url: string
  status: string
}

interface AudioPlayerContextValues {
  setPlaylist: (tracks: Track[]) => void
  addToPlaylist: (tracks: Track[]) => void
  removeFromPlaylist: (id: string) => void
  playlist: Track[]

  setPlaylistPlayId: (tracks: Track[], id: string) => void
  play: () => void
  playIndex: (index: number) => void
  playId: (id: string) => void
  pause: () => void
  back: () => void
  next: () => void
  setTrackPosition: (percent: number) => void
  currentTrack?: Track
  playing: boolean
  percent: number
}

export const AudioPlayerContext = createContext<AudioPlayerContextValues>({
  setPlaylist: () => {
    throw new Error('AudioPlayerContext is required')
  },
  addToPlaylist: () => {
    throw new Error('AudioPlayerContext is required')
  },
  removeFromPlaylist: () => {
    throw new Error('AudioPlayerContext is required')
  },
  playlist: [],
  setPlaylistPlayId: () => {
    throw new Error('AudioPlayerContext is required')
  },
  play: () => {
    throw new Error('AudioPlayerContext is required')
  },
  playIndex: () => {
    throw new Error('AudioPlayerContext is required')
  },
  playId: () => {
    throw new Error('AudioPlayerContext is required')
  },
  pause: () => {
    throw new Error('AudioPlayerContext is required')
  },
  back: () => {
    throw new Error('AudioPlayerContext is required')
  },
  next: () => {
    throw new Error('AudioPlayerContext is required')
  },
  setTrackPosition: () => {
    throw new Error('AudioPlayerContext is required')
  },
  playing: false,
  percent: -1
})

export const AudioPlayerProvider = ({ children }: PropsWithChildren<any>) => {
  const [playlist, setPlaylist] = useState<Track[]>([])
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(-1)
  const [currentTrack, setCurrentTrack] = useState<Track | undefined>()
  const [playing, setPlaying] = useState<boolean>(false)
  const [percent, setPercent] = useState<number>(0)
  const ref = useRef<HTMLAudioElement>(null)

  const playIndex = (i: number) => {
    setPercent(0)
    console.log('AudioPlayer play index', i)
    setCurrentTrack(playlist[i])
    setCurrentTrackIndex(i)
    ref.current!.src = playlist[i].url
    ref.current!.play()
  }

  const playId = (id: string) => {
    setPercent(0)
    console.log('AudioPlayer play id', id)
    const trackIndex = playlist.findIndex(item => item.id === id)
    if (trackIndex === -1) {
      console.log('AudioPlayer dont find id', id)
      return
    }
    setCurrentTrack(playlist[trackIndex])
    setCurrentTrackIndex(trackIndex)
    ref.current!.src = playlist[trackIndex].url
    ref.current!.play()
  }

  const back = () => {
    console.log('AudioPlayer back', currentTrackIndex)
    if (ref.current!.currentTime > 5) {
      ref.current!.currentTime = 0
      return
    }
    if (playlist[currentTrackIndex - 1]) {
      playIndex(currentTrackIndex - 1)
    }
  }

  const next = () => {
    console.log('AudioPlayer next', currentTrackIndex)
    if (playlist[currentTrackIndex + 1]) {
      playIndex(currentTrackIndex + 1)
    }
  }

  const removeFromPlaylist = (id: string) => {
    const newPlaylist = playlist.filter(track => track.id !== id)
    const newCurrentTrackIndex = newPlaylist.findIndex(track => track.id === currentTrack?.id)
    if (newCurrentTrackIndex !== -1) {
      setCurrentTrackIndex(newCurrentTrackIndex)
    }
    setPlaylist(newPlaylist)
  }

  useEffect(() => {
    navigator.mediaSession.setActionHandler('previoustrack', () => {
      back()
    })
    navigator.mediaSession.setActionHandler('nexttrack', () => {
      next()
    })
  }, [ref, ref.current, back, next, currentTrackIndex, currentTrack, playlist])

  useEffect(() => {
    if (!currentTrack) {
      navigator.mediaSession.metadata = new MediaMetadata({})
      return
    }
    navigator.mediaSession.metadata = new MediaMetadata({
      title: currentTrack.name,
      artist: currentTrack.artist.name,
      album: currentTrack.release.name,
      artwork: [{
        src: `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${currentTrack?.artist.id}/release/${currentTrack?.release.id}/artwork`
      }]
    })
  }, [currentTrack])

  useEffect(() => {
    ref.current!.onplaying = () => {
      stores.ws.rtSend('music-started', {
        artist: currentTrack?.artist,
        release: currentTrack?.release,
        track: currentTrack
      })
      setPlaying(true)
    }
    ref.current!.onpause = () => {
      stores.ws.rtSend('music-paused', {
        artist: currentTrack?.artist,
        release: currentTrack?.release,
        track: currentTrack
      })
      setPlaying(false)
    }
    ref.current!.onended = () => {
      stores.ws.rtSend('music-listened', {
        artist: currentTrack?.artist,
        release: currentTrack?.release,
        track: currentTrack
      })
      next()
    }
    ref.current!.ontimeupdate = () => {
      if (!ref.current) {
        return
      }
      setPercent(ref.current!.currentTime / ref.current!.duration)
    }
  }, [ref, ref.current, back, next, currentTrackIndex, currentTrack, playlist])

  return (
    <>
      <audio id="audio-player" ref={ref} />
      <AudioPlayerContext.Provider
        value={{
          setPlaylistPlayId: (tracks: Track[], id: string) => {
            setPlaylist(tracks)
            const trackIndex = tracks.findIndex(item => item.id === id)
            if (trackIndex === -1) {
              throw new Error('AudioPlayer cannot find this is')
            }
            setCurrentTrack(tracks[trackIndex])
            setCurrentTrackIndex(trackIndex)
            ref.current!.src = tracks[trackIndex].url
            ref.current!.play()
          },
          setPlaylist: (tracks: Track[]) => {
            console.log('AudioPlayer set playlist', tracks)
            setPlaylist(tracks)
            setCurrentTrackIndex(0)
            // eslint-disable-next-line no-undefined
            setCurrentTrack(undefined)
          },
          addToPlaylist: (tracks: Track[]) => {
            setPlaylist(_playlist => [..._playlist, ...tracks])
          },
          removeFromPlaylist,
          playlist,
          play: () => {
            ref.current?.play()
          },
          playIndex,
          playId,
          pause: () => {
            ref.current?.pause()
          },
          back: () => {
            back()
          },
          next: () => {
            next()
          },
          setTrackPosition: (p) => {
            ref.current!.currentTime = p * ref.current!.duration
          },
          currentTrack,
          playing,
          percent
        }}
      >
        {children}
      </AudioPlayerContext.Provider>
    </>
  )
}

export const useAudioPlayer = () => useContext(AudioPlayerContext)
