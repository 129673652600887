import { useEffect, useState } from 'react'
import Query from '../tools/query'
import async from 'async'
import NProgress from 'nprogress'

import Grid from '../components/grid'

import { tmdb } from '../tools/api'
import { genres } from '../tools/genres'
import { stores } from '../tools/store'

type Genre = keyof typeof genres

function isStringGenre(stringGenre: string): stringGenre is Genre {
  return Object.keys(genres).includes(stringGenre)
}

export function Search() {
  const { location } = window
  const lang = stores.lang.get()
  const [currentQuery, setCurrentQuery] = useState(Query.parse(location.search).query)
  const [shows, setShows] = useState<TMDB.MovieDiscoverResult[] | TMDB.TVDiscoverResult[]>([])
  const [page, setPage] = useState(1)

  function getSearch() {
    return new Promise<void>(resolve => {
      NProgress.start()
      const params = Query.parse(location.search)
      const regexp = /(#|%23)([a-zA-Z]+)/g
      const searchQueryGenres: number[] = []
      let match: RegExpExecArray | null = null
      while ((match = regexp.exec(params.query)) !== null) {
        const [,, genre] = match
        if (isStringGenre(genre) && genres[genre]) {
          searchQueryGenres.push(genres[genre])
        }
      }

      let url = []

      if (searchQueryGenres.length > 0) {
        url = [
          `discover/movie?language=${lang}&page=${page}${`&with_genres=${searchQueryGenres.join(',')}`}&sort_by=popularity.desc`,
          `discover/tv?language=${lang}&page=${page}${`&with_genres=${searchQueryGenres.join(',')}`}&sort_by=popularity.desc&include_video=false`
        ]
      } else if (params.type === 'people') {
        url = [
          `discover/movie?language=${lang}&page=${page}${`&with_cast=${params.query}`}&sort_by=popularity.desc`
        ]
      } else {
        const includeAdult = params.query.includes('%23adult')
        url = [`search/multi?query=${params.query.replace('%23adult', '')}&language=${lang}&page=${page}&include_adult=${includeAdult}`]
      }

      NProgress.inc()
      async.eachOf(
        url,
        (value, key, callback) => {
          tmdb
            .get(value)
            .then(show => {
              setShows(oldShows => [...oldShows, ...show.data.results])
              NProgress.inc()
              return callback()
            })
            .catch(err => {
              if (err) {
                console.log(err)
              }
              NProgress.inc()
              return callback()
            })
        },
        err => {
          if (err) {
            console.log(err)
          }
          NProgress.done()
          console.log(page)

          return resolve()
        }
      )
    })
  }

  async function startSearch() {
    await getSearch()
    stores.currentPath.setCurrentPath([])
  }

  useEffect(() => {
    setShows([])

    startSearch()
  }, [])

  useEffect(() => {
    const params = Query.parse(location.search)

    if (params.query !== currentQuery) {
      setCurrentQuery(params.query)
      setShows([])

      startSearch()
    }
  }, [location.search, currentQuery])

  useEffect(() => {
    if (page !== 1) {
      getSearch()
    }
  }, [page])

  function scroll() {
    setPage(oldPage => oldPage + 1)
  }

  return <Grid shows={shows} infiniteFunc={scroll} />
}
