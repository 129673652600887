import { useEffect, useState } from 'react'

import { tmdb } from '../tools/api'

import Show from '../components/show'

import './tvshow.css'
import { stores } from '../tools/store'
import utils from '../tools/utils'

interface TvshowProps {
  match: any
}

function Tvshow(props: TvshowProps) {
  const { match } = props
  const [show, setShow] = useState<TMDB.TvShow | null>(null)
  const lang = 'en-US'

  useEffect(() => {
    async function fetchTvShowInfo() {
      const fetchShow = await tmdb.get<TMDB.TvShow>(`tv/${match.params.id}?language=${lang}`).catch(error => {
        console.log(error)
      })

      if (!fetchShow) {
        return
      }

      setShow(fetchShow.data)
      const showName = utils.isJapaneseCharacters(fetchShow.data.original_name)
        ? fetchShow.data.name
        : fetchShow.data.original_name

      stores.currentPath.setCurrentPath([showName])

      // @ts-ignore
      if (window.cast) {
        // @ts-ignore
        const session = window.cast.framework.CastContext.getInstance().getCurrentSession()
        if (session) {
          console.log(show)
          session.sendMessage(
            'urn:x-cast:tv.iziplay.chromecast',
            {
              type: 'show',
              data: {
                name: fetchShow.data?.name,
                backdrop_path: fetchShow.data?.backdrop_path,
                number_of_seasons: fetchShow.data?.number_of_seasons,
                first_air_date: fetchShow.data?.first_air_date,
                in_production: fetchShow.data?.in_production,
                overview: fetchShow.data?.overview
              }
            },
            () => stores.notification.displayNotification('Cast is ready', 'success'),
            () => stores.notification.displayNotification('Problem with cast', 'error')
          )
        }
      }
    }

    fetchTvShowInfo()
  }, [])

  if (!show) {
    return <p>{'Loading...'}</p>
  }
  return (
    <div className="grid scrollable">
      {show.seasons.map(season => (
        <Show
          key={`${show.id}_${season.season_number}`}
          details={season}
          url={`/tvshows/${show.id}/${season.season_number}?backdrop=${show.backdrop_path}`}
        />
      ))}
    </div>
  )
}

export default Tvshow
