import { useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import './sidebar.css'

import { stores } from '../tools/store'
import { observer } from 'mobx-react'
import { Chip } from '@material-ui/core'
import { AudioPlayer } from './audioPlayer/component'

interface SidebarProps {}

function Sidebar(props: SidebarProps) {
  const user = stores.user.get()

  useEffect(() => {
    stores.user.fetchUser()
  }, [])

  function closeMobileSidebar() {
    stores.mobileSidebar.setMobileSidebar(false)
  }

  return (
    <div
      className="sidebar"
      style={{
        display:
          (stores.mobileSidebar.get() && window.innerWidth < 768) || window.innerWidth >= 768
            ? 'block'
            : 'none'
      }}
    >
      <div className="avatar">
        <div
          className="img"
          style={{
            backgroundImage: `url("https://www.gravatar.com/avatar/${user.mailHash}?s=200&d=identicon")`
          }}
        />
        <div className="bubble" style={{ opacity: stores.privateMode.get() ? 1 : 0 }} />
        <div className="name">{user.username}</div>
      </div>
      <div className="nav">
        <NavLink
          onClick={closeMobileSidebar}
          className="link"
          to="/downloaded"
          activeClassName="active"
        >
          <img alt="arc" className="emoji" src="/img/emoji/emoji_u1f308.svg" /> Watch Now{' '}
          <img alt="star" className="emoji" src="/img/emoji/emoji_u2b50.svg" />
        </NavLink>
        <NavLink
          onClick={closeMobileSidebar}
          className="link"
          to="/myhistory"
          activeClassName="active"
        >
          My History
        </NavLink>
        {user.tvTime ? (
          <NavLink className="link last" to="/tvtime/towatch" activeClassName="active">
            TV Time: to watch
          </NavLink>
        ) : null}
        <NavLink
          onClick={closeMobileSidebar}
          className="link last"
          to="/movies"
          activeClassName="active"
        >
          Movies
        </NavLink>
        <NavLink
          onClick={closeMobileSidebar}
          className="link"
          to="/tvshows"
          activeClassName="active"
        >
          Tv Shows
        </NavLink>
        <NavLink
          onClick={closeMobileSidebar}
          className="link"
          to="/music"
          activeClassName="active"
        >
          Music <Chip size="small" label="BETA" />
        </NavLink>
        <NavLink
          onClick={closeMobileSidebar}
          className="link last"
          to="/settings"
          activeClassName="active"
        >
          Settings
        </NavLink>
        <NavLink
          onClick={closeMobileSidebar}
          className="link"
          to="/support"
          activeClassName="active"
        >
          Support
        </NavLink>
        {user.role >= 1 ? (
          <NavLink
            onClick={closeMobileSidebar}
            className="link last"
            to="/stats"
            activeClassName="active"
          >
            Stats
          </NavLink>
        ) : null}
        {user.role >= 1 ? (
          <NavLink
            onClick={closeMobileSidebar}
            className="link"
            to="/admin"
            activeClassName="active"
          >
            <img alt="urss" className="emoji" src="/img/emoji/emoji_u1f6e0.svg" /> Admin
          </NavLink>
        ) : null}
        <div style={{ position: 'absolute', bottom: 0, left: 0 }}>
          <AudioPlayer />
        </div>
      </div>
    </div>
  )
}

export default withRouter(observer(Sidebar))
