import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { stores } from '../tools/store'

import Grid from '../components/grid'

function Movies() {
  useEffect(() => {
    if (stores.movies.get().length === 0) {
      stores.movies.fetchMovies()
    }

    // @ts-ignore
    if (window.cast) {
      // @ts-ignore
      const session = window.cast.framework.CastContext.getInstance().getCurrentSession()
      if (session) {
        session.sendMessage(
          'urn:x-cast:tv.iziplay.chromecast',
          {
            type: 'home'
          },
          () => stores.notification.displayNotification('Cast is ready', 'success'),
          () => stores.notification.displayNotification('Problem with cast', 'error')
        )
      }
    }
    stores.currentPath.setCurrentPath([])
  }, [])

  function scroll() {
    stores.moviesPage.nextMoviesPage()
    stores.movies.fetchMovies()
  }

  return <Grid shows={stores.movies.get()} infiniteFunc={scroll} />
}

export default observer(Movies)
