import { useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { iziplaySubtitle } from '../tools/api'

interface SubtitleModalProps {
  onClose?: () => void
  fileId: string
  open: boolean
  onUpload?: ({ id, lang }: { id: string; lang: string }) => void
}

function SubtitleModal(props: SubtitleModalProps) {
  const { onClose, onUpload, fileId, open } = props
  const [name, setName] = useState('No name')
  const [lang, setLang] = useState('eng')

  const callOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const selectFile = (evt: any) => {
    console.log(props)
    console.log(evt)
    const reader = new FileReader()
    reader.onload = async event => {
      const srt =
        typeof event?.target?.result === 'string'
          ? event?.target?.result.replace('data:application/octet-stream;base64,', '')
          : ''
      const res = await iziplaySubtitle().post(`/file/${fileId}/srt`, {
        name,
        lang,
        srt
      })

      if (onClose) {
        onClose()
      }
      if (onUpload) {
        onUpload({
          id: res.data.internalID,
          lang
        })
      }
    }
    reader.readAsDataURL(evt.target.files[0])
  }

  return (
    <Dialog onClose={callOnClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle>Import a subtitle</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Import a SRT file from your computer that will be visible in the player and Chromecast if
          available
        </DialogContentText>
        <TextField
          label="Name"
          value={name}
          onChange={evt => {
            setName(evt.target.value || '')
          }}
        />

        <br />
        <br />

        <FormControl>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={lang}
            onChange={evt => {
              setLang(evt.target.value as string)
            }}
          >
            <MenuItem value={'eng'}>English</MenuItem>
            <MenuItem value={'fra'}>French</MenuItem>
            <MenuItem value={''}>N/A</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            onChange={selectFile}
          />
          <Button className="btn-choose" component="span">
            Select file
          </Button>
        </label>
      </DialogActions>
    </Dialog>
  )
}

export { SubtitleModal }
