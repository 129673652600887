import { Chart, ChartData, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, BarElement } from 'chart.js'
import { useEffect, useState } from 'react'
import { Bar, Doughnut } from 'react-chartjs-2'

import { iziplaySocial } from '../tools/api'
import { stores } from '../tools/store'

import './stats.css'

Chart.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, BarElement)

type State = {
  viewingByDay: ChartData<'bar', (number | [number, number] | null)[], unknown>
  viewingByDayname: ChartData<'doughnut', (number | [number, number] | null)[], unknown>
}

function Stats() {
  const [state, setState] = useState<State>({
    viewingByDay: {
      labels: [],
      datasets: [
        {
          label: 'views',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: []
        }
      ]
    },
    viewingByDayname: {
      labels: [],
      datasets: [
        {
          label: 'views',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderWidth: 1,
          data: []
        }
      ]
    }
  })

  useEffect(() => {
    iziplaySocial()
      .get('/stats/viewingByDay')
      .then(res => {
        const labels: any[] = []
        const data: any[] = []
        res.data.stats.forEach((day: any) => {
          labels.push(`${day.year}-${day.month}-${day.day}`)
          data.push(day.count)
        })
        const viewingByDay = { ...state.viewingByDay }
        viewingByDay.labels = labels
        viewingByDay.datasets[0].data = data
        setState(oldState => ({ ...oldState, viewingByDay }))
      })
    iziplaySocial()
      .get('/stats/viewingByDayname')
      .then(res => {
        const labels = [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ]
        const data: any[] = []
        labels.forEach(day => {
          for (const dayPoint of res.data.stats) {
            if (dayPoint.dayname === day) {
              data.push(dayPoint.count)
            }
          }
        })
        const viewingByDayname = { ...state.viewingByDayname }
        viewingByDayname.labels = labels
        viewingByDayname.datasets[0].data = data
        viewingByDayname.datasets[0].backgroundColor = [
          'rgb(26, 188, 156)',
          'rgb(46, 204, 113)',
          'rgb(52, 152, 219)',
          'rgb(155, 89, 182)',
          'rgb(241, 196, 15)',
          'rgb(230, 126, 34)',
          'rgb(231, 76, 60)'
        ]
        setState(oldState => ({ ...oldState, viewingByDayname }))
      })
    iziplaySocial().get('/stats/downloadsByDay')
    stores.currentPath.setCurrentPath([])
  }, [])

  return (
    <section className="stats-page">
      <h1>Stats</h1>
      <article className="views-from-beginning-chart">
        <h2>Views from beginning</h2>
        {state.viewingByDay.labels && state.viewingByDay.labels.length > 0 ? (
          <Bar
            height={50}
            data={state.viewingByDay}
            options={{
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }}
          />
        ) : (
          <p>Loading...</p>
        )}
      </article>
      <article className="views-by-dayname-chart">
        <h2>Views by day name</h2>
        {state.viewingByDayname.labels && state.viewingByDayname.labels.length > 0 ? (
          <Doughnut data={state.viewingByDayname} />
        ) : (
          <p>Loading...</p>
        )}
      </article>
    </section>
  )
}

export default Stats
