import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { App } from './pages/app'
import Login from './pages/login'
import NotFound from './pages/notfound'
import Debug from './pages/debug'
import './index.css'
import { ThemeProvider } from '@material-ui/styles'
import reportWebVitals from './reportWebVitals'

import { stores } from './tools/store'
import { observer } from 'mobx-react'

const Index = observer(() => {
  useEffect(() => {
    stores.theme.setTheme(global.localStorage.theme || 'light')
  }, [])

  return (
    <ThemeProvider theme={stores.theme.muiTheme}>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/debug" component={Debug} />
          <Route path="/:all" component={App} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  )
})

ReactDOM.render(<Index />, document.getElementById('root'))

reportWebVitals()
