import axios from 'axios'
import { useEffect, useState } from 'react'
import Spinner from '../../components/spinner'
import { Button, Card, CardActionArea, CardContent, CardMedia, Chip, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { stores } from '../../tools/store'

export function Music() {
  const history = useHistory()
  const user = stores.user.get()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [releases, setReleases] = useState<any[]>([])

  const fetchReleases = async () => {
    const res = await axios.request({
      method: 'GET',
      url: `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/release/completed`
    })
    setReleases(res.data.releases)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchReleases()
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="scrollable" style={{ padding: '12px' }}>
      {user.role >= 1 && (
        <Card variant="outlined" style={{ marginBottom: '12px' }}>
          <CardContent>
            <Chip size="small" label="ADMIN ONLY" />
            <Button
              onClick={() => {
                history.push('/music/search')
              }}
            >
              Search
            </Button>
            <a href="https://music.iziplay.fruitice.fr/status" target="_blank" rel="noreferrer">Status</a>
          </CardContent>
        </Card>
      )}
      <Card variant="outlined" style={{ marginBottom: '12px' }}>
        <CardContent>
          <Typography>This feature is in <Chip size="small" label="BETA" /> it is not finished and can have bugs, thank you in advance for your understanding</Typography>
        </CardContent>
      </Card>
      <Grid container spacing={1}>
        {releases.map(release => (
          <Grid key={release.id} item xs={12} sm={6} md={4} lg={2}>
            <Card variant="outlined">
              <CardActionArea
                onClick={() => {
                  history.push(`/music/${release.artist.name}/${release.name}`)
                }}
              >
                <CardMedia
                  style={{ height: '180px' }}
                  image={`${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${release.artist.id}/release/${release.id}/artwork?size=360`}
                  title={`Cover of ${release.name}`}
                />
                <CardContent>
                  <Typography variant="body1" component="h2" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {release.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {release.artist.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
