import './notification.css'
import { observer } from 'mobx-react'
import { stores } from '../tools/store'

function Notification() {
  return (
    <div
      className={stores.notification.get().classes}
      style={{ backgroundColor: stores.notification.get().color }}
    >
      <div className="notificationMessage">{stores.notification.get().message}</div>
    </div>
  )
}

export default observer(Notification)
