import { useEffect } from 'react'

import { observer } from 'mobx-react'
import { stores } from '../tools/store'

import Grid from '../components/grid'

function Downloaded() {
  useEffect(() => {
    if (stores.downloaded.get().length === 0) {
      stores.downloaded.fetchDownloaded()
    }
    stores.currentPath.setCurrentPath([])
  }, [])

  function scroll() {
    stores.downloadedPage.nextDownloadedPage()
    stores.downloaded.fetchDownloaded()
  }

  return (
    <Grid
      shows={stores.downloaded.get()}
      infiniteFunc={() => {
        scroll()
      }}
    />
  )
}

export default observer(Downloaded)
