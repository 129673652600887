import { useEffect, useRef } from 'react'
import Show from './show'
import './grid.css'

import { isObjectPerson } from '../tools/typeGuards'

interface GridProps {
  infiniteFunc: () => void
  shows: (
    | TMDB.Movie
    | TMDB.TvShow
    | Iziplay.Downloaded
    | TMDB.MovieDiscoverResult
    | TMDB.TVDiscoverResult
    | TMDB.Person
  )[]
}

function Grid(props: GridProps) {
  const { shows, infiniteFunc } = props
  const gridRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const domNode = gridRef.current
    let already = 0

    if (domNode) {
      domNode.addEventListener('scroll', event => {
        const { target } = event
        if (
          !already &&
          // @ts-ignore
          parseInt(target.scrollHeight - target.scrollTop - target.offsetHeight, 10) === 0
        ) {
          already = 1
          infiniteFunc()
          already = 0
        }
      })
    }
  }, [])

  const mappedShows = shows.map((show, i) => {
    if (!show) return null

    if (isObjectPerson(show) && show.known_for && show.media_type === 'person') {
      const knownForShow = show.known_for
      return knownForShow.map((know: any) => <Show key={know.id} details={know} />)
    }
    return <Show key={i} details={show} />
  })
  return (
    <div className="grid scrollable" ref={gridRef}>
      {mappedShows}
    </div>
  )
}

export default Grid
