import { ReactNode, ChangeEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { isStringQuality, isObjectMovie } from '../tools/typeGuards'
import utils from '../tools/utils'

import './Overview.css'

interface OverviewProps {
  show: TMDB.Movie | TMDB.Episode
  clickQuality(quality: Iziplay.Downloaded): void
  progress: number
  qualities: Iziplay.Downloaded[]
  casts: TMDB.GuestStar[]
  similar: (TMDB.Movie | TMDB.Episode)[]
  available: Iziplay.Downloaded[]
  countries: string[]
  backdrop: string
  type: string
  time?: Iziplay.Time
}

type Selected = Partial<Record<Iziplay.Downloaded['quality'], Iziplay.Downloaded>>

export function Overview(props: OverviewProps) {
  const history = useHistory()
  const {
    show,
    clickQuality,
    progress,
    qualities,
    casts,
    similar,
    available,
    countries,
    backdrop,
    type,
    time
  } = props
  const [selected, setSelected] = useState<Selected>({})
  let countryMovieElement = null

  if (type === 'movie') {
    countryMovieElement =
      countries.length === 0 ? (
        <h3 className="durate" style={{ color: 'var(--red)' }}>
          This film hasn&apos;t been released in theaters yet
        </h3>
      ) : (
        <p className="durate">
          Available in{' '}
          {countries.map((country, index) => (
            <span key={index}>{country} </span>
          ))}
        </p>
      )
  }

  function changeSelect(event: ChangeEvent<HTMLSelectElement>) {
    const data = event.target.selectedOptions?.[0].getAttribute('value')
    if (!data) {
      return
    }
    const quality: Iziplay.Downloaded | null = JSON.parse(data)

    if (!quality) {
      return
    }

    quality.audio ||= 'VO'

    setSelected(oldSelected => ({
      ...oldSelected,
      [quality.quality]: quality
    }))
  }

  function getSelected(qual: string) {
    if (isStringQuality(qual)) {
      return selected[qual]
    }
  }

  function createQualities() {
    const optionsQualities = qualities.reduce<
      Partial<Record<Iziplay.Downloaded['quality'], ReactNode[]>>
    >((reducedQualities, quality) => {
      const optionQual = (
        <option
          key={quality.id}
          className={`qualityOption${quality.downloaded ? ' downloaded' : ''}`}
          value={JSON.stringify(quality)}
        >
          {quality.audio} ({quality.provider})
        </option>
      )
      reducedQualities[quality.quality] = [...(reducedQualities[quality.quality] || []), optionQual]
      return reducedQualities
    }, {})

    const localQualities = Object.keys(optionsQualities).map(qual => {
      if (!isStringQuality(qual)) return null

      const selectesQuality = getSelected(qual)

      return (
        <div key={qual} className="qualityCard">
          <div className="categoryTitle">{qual}</div>
          <>
            <div className="qualityInfo">Name: {selectesQuality?.name || ''}</div>
            <div className="qualityInfo">Audio: {selectesQuality?.audio || ''}</div>
            <div className="qualityInfo">Provider: {selectesQuality?.provider || ''}</div>
            <div className="qualityInfo">Seeds: {selectesQuality?.seeds || ''}</div>
          </>
          <select className="qualitySelect qualityOption" onChange={changeSelect}>
            <option className="qualityOption">...</option>
            {optionsQualities[qual]}
          </select>
          <Button
            disabled={!selectesQuality}
            variant="contained"
            color={selectesQuality?.downloaded ? 'secondary' : 'primary'}
            style={{ marginTop: '0.8em' }}
            key={qual}
            onClick={() => selectesQuality && clickQuality(selectesQuality)}
            className={`quality${selectesQuality?.downloaded ? ' downloaded' : ''}`}
          >
            Watch
          </Button>
        </div>
      )
    })

    return localQualities
  }

  const localQualities = createQualities()
  const showTitle = isObjectMovie(show) ? show.title : show.name
  const showAdult = isObjectMovie(show) ? show.adult : false
  const showDurate = isObjectMovie(show) ? show.durate : ''
  const showDate = isObjectMovie(show) ? show.release_date : show.air_date
  const showBackdrop = (isObjectMovie(show) && show.backdrop_path) || backdrop || ''
  const showPoster = (isObjectMovie(show) ? show.poster_path : show.still_path) || ''

  return (
    <div className="scrollable-nopadding movie">
      <div
        className="background"
        style={{
          backgroundImage: `url('https://image.tmdb.org/t/p/original/${showBackdrop}')`
        }}
      >
        <div className="poster_div">
          <div
            className={`poster${type === 'tvshow' ? ' poster-tv' : ''}`}
            style={
              showPoster
                ? {
                    backgroundImage: `url('https://image.tmdb.org/t/p/original/${showPoster}')`
                  }
                : {
                    backgroundColor: 'var(--black)',
                    backgroundImage: "url('/img/white-50.png')"
                  }
            }
          />
          <div className="titles">
            <div className="title">
              <div className="name">
                {showTitle} {showAdult && '🔞'}
              </div>
              <div className="date">{showDate}</div>
            </div>
            {time ? (
              <div className="durate">
                Saw {utils.transformDurateSeconds(time.time)} at{' '}
                {new Date(time.updated).toLocaleString()}
              </div>
            ) : (
              <div className="durate">You never saw this film</div>
            )}
            <div className="durate">{showDurate}</div>
            <div className="immediatelyPlayableButtonContainer">
              {available.map((a, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  className="playable"
                  onClick={() => clickQuality(a)}
                >
                  Immediately playable ({a.quality}, {a.audio || 'VO'})
                </Button>
              ))}
            </div>
            {countryMovieElement}
          </div>
        </div>
      </div>

      <div className="details">
        <div className="infos">
          <div className="categoryTitle">Qualities</div>
          <LinearProgress
            style={{ marginTop: '0.4em', marginBottom: '0.8em' }}
            variant={progress > 0 ? 'determinate' : 'indeterminate'}
            value={progress * 100}
          />
          {localQualities.length === 0 && Math.ceil(progress * 100) === 100 ? (
            <div style={{ height: '10em' }}>Sorry, no file has been found...</div>
          ) : (
            <div className="qualities">{localQualities}</div>
          )}
        </div>

        {show.overview && (
          <div className="infos">
            <div className="categoryTitle">Synopsis</div>
            <div className="overview">{show.overview}</div>
          </div>
        )}

        {casts.length > 0 && (
          <div className="infos">
            <div className="categoryTitle">The Cast</div>
            <div className="castingList">
              {casts.map(people => (
                <div key={people.id} className="cast_items">
                  <div
                    className="castingPoster"
                    onClick={() => {
                      history.push(`/search?type=people&query=${encodeURIComponent(people.id)}`)
                    }}
                    style={{
                      backgroundImage: `url('${
                        people.profile_path
                          ? `https://image.tmdb.org/t/p/w300${people.profile_path}`
                          : null
                      }')`
                    }}
                  />
                  <div className="castingName">{people.name}</div>
                  <div className="castingCharacter">{people.character}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        {similar.length > 0 && (
          <div className="infos">
            <div className="categoryTitle">Similar</div>
            <div className="similarList">
              {similar.map(sim => {
                const onClickSimilar = () => {
                  history.push(`/movies/${sim.id}`)
                  document.getElementsByClassName('movie')[0].scrollTo(0, 0)
                }

                return (
                  <div key={sim.id} className="similar_items">
                    <div
                      className="similarPoster"
                      onClick={onClickSimilar}
                      style={{
                        backgroundImage: `url('${
                          isObjectMovie(sim)
                            ? `https://image.tmdb.org/t/p/w300${sim.poster_path}`
                            : null
                        }')`
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
