import axios from 'axios'
const tmdbOriginal = 'https://api.themoviedb.org/3/'
const tmdbProxy = 'https://tmdb-proxy.iziplay.fruitice.fr/3/'

export const tmdb = axios.create({
  baseURL: tmdbOriginal,
  params: {
    api_key: 'fbf9e5c17116a6b2ea78c915418a2e5c',
    include_video: false
  }
})

console.log(tmdb.interceptors)
tmdb.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status !== 429) {
      return Promise.reject(error)
    }

    error.config.baseURL = tmdbProxy
    error.config.url = error.config.url.replace(tmdbOriginal, tmdbProxy)

    console.log('TMDB 429: retry with proxy')
    return axios.request(error.config)
  }
)

export const iziplaySupport = () =>
  axios.create({
    baseURL: process.env.REACT_APP_IZIPLAY_URL_SUPPORT,
    headers: {
      Authorization: `Bearer ${global.localStorage.token}`
    }
  })

export const iziplayAuth = () =>
  axios.create({
    baseURL: process.env.REACT_APP_IZIPLAY_URL_AUTH,
    headers: {
      Authorization: `Bearer ${global.localStorage.token}`
    },
    withCredentials: true
  })

export const iziplaySearch = () =>
  axios.create({
    baseURL: process.env.REACT_APP_IZIPLAY_URL_SEARCH,
    headers: {
      Authorization: `Bearer ${global.localStorage.token}`
    }
  })

export const iziplaySocial = () =>
  axios.create({
    baseURL: process.env.REACT_APP_IZIPLAY_URL_SOCIAL,
    headers: {
      Authorization: `Bearer ${global.localStorage.token}`
    }
  })

export const iziplaySubtitle = () =>
  axios.create({
    baseURL: process.env.REACT_APP_IZIPLAY_URL_SUBTITLE,
    headers: {
      Authorization: `Bearer ${global.localStorage.token}`
    }
  })

export const hls = (custom?: string) =>
  axios.create({
    baseURL: custom || process.env.REACT_APP_IZIPLAY_URL_HLS,
    headers: {
      Authorization: `Bearer ${global.localStorage.token}`
    }
  })

export const local = () =>
  axios.create({
    baseURL: 'http://localhost:3001/v1',
    headers: {
      Authorization: `Bearer ${global.localStorage.token}`
    }
  })
