import { useState, useEffect } from 'react'
import { useHistory, Switch, Route } from 'react-router-dom'
import { observer } from 'mobx-react'

import Sidebar from '../components/sidebar'
import Notification from '../components/notification'
import Header from '../components/header'
import { SearchBar } from '../components/searchbar'

import Downloaded from './downloaded'

import Movies from './movies'
import Movie from './movie'
import TvShows from './tvshows'
import TvShow from './tvshow'
import { Season } from './season'
import { Search } from './search'
import { Settings } from './settings'
import Support from './support'
import Stats from './stats'
import { Admin } from './admin'

import MyHistory from './myhistory'
import TvtimeTowatch from './tvtimetowatch'

import HlsPlayer from './hlsplayer'

import Spinner from '../components/spinner'

import { stores } from '../tools/store'
import './app.css'
import { MusicSearch } from './music/search'
import { MusicRelease } from './music/release'
import { Playlist } from './music/playlist'
import { AudioPlayerProvider } from '../components/audioPlayer/provider'
import { Music } from './music'
import { MusicArtist } from './music/artist'

export const App = observer(() => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    if (!global.localStorage.getItem('token')) {
      history.push('/')
      return
    }
    stores.ws.connectWs()

    async function fetchUser() {
      try {
        await stores.user.fetchUser()
        setLoading(false)
      } catch (err) {
        setLoading(false)
        if (typeof err !== 'string') return
        setError(err)
      }
    }

    fetchUser()
  }, [])

  if (loading) {
    return (
      <div style={{ width: '100%', margin: 'auto' }}>
        <Spinner />
        <p style={{ textAlign: 'center' }}>Loading settings...</p>
      </div>
    )
  }

  if (error) {
    return <code>{JSON.stringify(error, null, 2)}</code>
  }

  return (
    <div className="router">
      <AudioPlayerProvider>
        <Sidebar />
        <div className="container">
          <Notification />
          <Header
            title={
              stores.currentPath.get().length > 0
                ? stores.currentPath.get().join(' / ')
                : 'Enjoy beautiful contents'
            }
          />
          <SearchBar />
          <Switch>
            <Route exact path="/downloaded" component={Downloaded} />
            <Route exact path="/movies" component={Movies} />
            <Route exact path="/movies/:id" component={Movie} />
            <Route exact path="/tvshows" component={TvShows} />
            <Route exact path="/tvshows/:id" component={TvShow} />
            <Route exact path="/tvshows/:id/:season" component={Season} />
            <Route exact path="/tvshows/:id/:season/:episode" component={Movie} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/music" component={Music} />
            <Route exact path="/music/search" component={MusicSearch} />
            <Route exact path="/music/playlist" component={Playlist} />
            <Route exact path="/music/:artist" component={MusicArtist} />
            <Route exact path="/music/:artist/:release" component={MusicRelease} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/stats" component={Stats} />
            <Route exact path="/admin" component={Admin} />

            <Route exact path="/myhistory" component={MyHistory} />
            <Route exact path="/tvtime/towatch" component={TvtimeTowatch} />
            {/* @ts-ignore TODO: need to refactor HlsPlayer */}
            <Route exact path="/hls/:file" component={HlsPlayer} />
          </Switch>
        </div>
      </AudioPlayerProvider>
    </div>
  )
})
