import { Component, ChangeEvent, createRef } from 'react'
import { observer } from 'mobx-react'

import { hls as apiHls, iziplaySubtitle, tmdb } from '../tools/api'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { stores } from '../tools/store'
import Query from '../tools/query'

import './hlsplayer.css'
import { SubtitleModal } from '../components/modalSubtitle'
import { MediaPlayer, MediaPlayerInstance, MediaProvider, Track } from '@vidstack/react'
import { VideoLayout } from '../components/player/videoLayout'
import async from 'async'

interface HlsPlayerProps {
  match: any
}

class HlsPlayer extends Component<HlsPlayerProps, any> {
  constructor(props: HlsPlayerProps) {
    super(props)

    this.state = {
      isSubtitleModalOpen: false,
      server: this.q.server
        ? `${this.q.server}/v1`
        : (process.env.REACT_APP_IZIPLAY_URL_HLS as string),
      playerId: Date.now(),
      subs: [],
      step: -1,
      ffprobe: null,
      castStatus: {}
    }
    console.log(`Use file ${this.state.server}/${this.props.match.params.file}/master.m3u8`)

    let device = 'web'
    if (window.location.host.indexOf('staging') !== -1) device = 'web-staging'
    if (window.location.host.indexOf('localhost') !== -1) device = 'web-localhost'

    this.media = {
      type: this._isTvShow() ? 'tvshow' : 'movie',
      tvdb: this.q.tvdb,
      tmdb: this.q.tmdb,
      tmdb_episode: this.q.tmdb_episode,
      imdb: this.q.imdb,
      season: this.q.season,
      episode: this.q.episode,
      device,
      file: props.match.params.file
    }

    this.connectWs()

    this.interval = setInterval(() => {
      if (this.video.current) {
        if (this.video.current && this.time === this.video.current?.currentTime) {
          return
        }
        this.time = this.video.current?.currentTime
      }
      this.media.time = this.time
      this.media.progress = (this.time / this.probeDuration) * 100
      stores.ws.rtSend('viewing', this.media)
    }, 4000)
  }

  video = createRef<MediaPlayerInstance>()
  q = Query.parse(window.location.search)
  listeningPlaying = false
  gettingSubs = false
  probeDuration: number = 1

  media = {} as any

  time = 0
  interval: NodeJS.Timeout | null = null
  ws: WebSocket | null = null

  steps = [
    'start',
    'downloadStart',
    'downloading',
    'probeStart',
    'probeEnd',
    'transcoderStart',
    'transcoding',
    'transcoderEnd',
    'stopped'
  ]

  connectWs() {
    this.ws = new window.WebSocket(
      `${this.state.server.replace('https', 'wss').replace('http', 'ws')}/${
        this.props.match.params.file
      }/ws`
    )
    this.ws.onmessage = msg => {
      const json = JSON.parse(msg.data)
      if (typeof json.error !== 'undefined' || json.type === 'error') {
        stores.notification.displayNotification(json.error || json.value.message, 'error')
      }
      const step = this.steps.indexOf(json.type)
      if (step > this.state.step) {
        this.setState({ step })
      }

      this.setState({ [json.type]: json.value })

      if (json.type === 'probeEnd') {
        this.ffprobe()
      }
    }
    this.ws.onclose = event => {
      if (event.code === 1006) return
      this.connectWs()
    }
  }

  componentDidMount() {
    apiHls(this.state.server)
      .get(`/${this.props.match.params.file}/isavailable`)
      .then(() => {
        console.log('File already started in server')
        this._initPlayer(true)
      })
      .catch(err => {
        console.log(err)
        switch (err.response?.status) {
          case 404:
            console.log('File does not exist on server, try to start it')
            apiHls(this.state.server)
              .put(`/${this.props.match.params.file}`)
              .then(() => {
                this._initPlayer()
              })
            break
          case 406:
            console.log('File is preparing')
            this._initPlayer()
            break
          default:
            stores.notification.displayNotification('Unknown error', 'error')
            console.log('Unknown error')
            console.log(err)
        }
      })

    stores.currentPath.setCurrentPath([this.props.match.params.file])

    if (!this.listeningPlaying) {
      this.listenPlaying()
    }
  }

  async startCast() {
    const castSession = (window as any).cast.framework.CastContext.getInstance().getCurrentSession()
    console.log((window as any).cast)
    console.log(castSession)
    const uri = `${this.state.server}/${this.props.match.params.file}/index.m3u8`.replace(
      'http:',
      ''
    ).replace('https:', '')

    console.log('Ask chromecast to start', uri)
    const mediaInfo = new (window as any).chrome.cast.media.MediaInfo(
      uri,
      'application/vnd.apple.mpegurl'
    )

    mediaInfo.tracks = []
    mediaInfo.startAbsoluteTime = 0
    if (this._isTvShow()) {
      const tvShow = await tmdb.get<TMDB.TvShow>(`tv/${this.q.tmdb}?language=${stores.lang.get()}`)
      const episode = await tmdb.get<TMDB.Episode>(`tv/${this.q.tmdb}/season/${this.q.season}/episode/${this.q.episode}?language=${stores.lang.get()}`)
      mediaInfo.metadata = new (window as any).chrome.cast.media.TvShowMediaMetadata()
      mediaInfo.metadata.seriesTitle = tvShow.data.name
      mediaInfo.metadata.season = parseInt(this.q.season, 10)
      mediaInfo.metadata.episode = parseInt(this.q.episode, 10)
      mediaInfo.metadata.serieTitle = episode.data.name
    } else {
      const movie = await tmdb.get<TMDB.Movie>(`movie/${this.q.tmdb}?language=${stores.lang.get()}`)
      mediaInfo.metadata = new (window as any).chrome.cast.media.MovieMediaMetadata()
      mediaInfo.metadata.title = movie.data.title
      mediaInfo.metadata.subtitle = 'Powered by Iziplay'
      mediaInfo.metadata.images = [new (window as any).chrome.cast.Image(movie.data.poster_path)]
      mediaInfo.metadata.studio = movie.data.production_companies.map(c => c.name).join(', ')
      mediaInfo.metadata.releaseDate = movie.data.release_date
    }

    this.state.subs.forEach((sub: any, i: number) => {
      // @ts-ignore
      const subtitle = new window.chrome.cast.media.Track(i + 1, window.chrome.cast.media.TrackType.TEXT)
      subtitle.trackContentId = sub.url
      subtitle.trackContentType = 'text/vtt'
      // @ts-ignore
      subtitle.subtype = window.chrome.cast.media.TextTrackType.SUBTITLES
      subtitle.name = sub.lang
      subtitle.language = sub.langId
      subtitle.customData = null
      mediaInfo.tracks.push(subtitle)
    })
    // @ts-ignore
    const request = new window.chrome.cast.media.LoadRequest(mediaInfo)
    if (this.state.subs[0]) {
      request.activeTrackIds = [1]
    }
    request.customData = this.media

    castSession.loadMedia(request).then(
      () => {
        // @ts-ignore
        const player = new window.cast.framework.RemotePlayer()
        // @ts-ignore
        const playerController = new window.cast.framework.RemotePlayerController(player)
        // @ts-ignore
        window.castPlayer = player
        // @ts-ignore
        window.castPlayerController = playerController
        this.setState({
          launched: true,
          castSession,
          player,
          playerController
        })

        playerController.addEventListener(
          // @ts-ignore
          window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
          (event: any) => {
            console.log('update', event)
            const { castStatus } = this.state
            castStatus[event.field] = event.value

            this.setState({ castStatus })
          }
        )

        console.log('sending viewing data to chromecast', this.media)
        castSession.sendMessage(
          'urn:x-cast:tv.iziplay.chromecast',
          {
            type: 'viewing',
            data: this.media
          },
          () => console.log('good'),
          () => console.log('bad')
        )
      },
      (errorCode: Error) => {
        console.log(`Error code: ${errorCode}`)
      }
    )
  }

  castPlayPause() {
    this.state.playerController.playOrPause()
  }

  changeCastSub(event: ChangeEvent<HTMLSelectElement>) {
    const id = parseInt(event.target.value, 10)
    // @ts-ignore
    const req = new window.chrome.cast.media.EditTracksInfoRequest([id])
    this.state.castSession.getMediaSession().editTracksInfo(
      req,
      () => console.log('good'),
      () => console.log('bad')
    )
  }

  openSubtitleModal() {
    this.setState({
      isSubtitleModalOpen: true
    })
  }

  closeSubtitleModal() {
    this.setState({
      isSubtitleModalOpen: false
    })
  }

  componentWillUnmount() {
    stores.ws.rtSend('stop', {
      progress: ((this.video.current?.currentTime ?? Infinity) / this.probeDuration) * 100,
      ...this.media
    })

    this.video.current?.destroy()
    this.ws?.close()
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  _isTvShow() {
    return this.q.season && this.q.episode
  }

  _initPlayer(alreadyDownloaded = false) {
    if (alreadyDownloaded) {
      this.ffprobe()
    }

    if (!this.gettingSubs) {
      this.gettingSubs = true
      const subs: any[] = []
      async.eachOf(
        stores.settings.get().subtitles,
        (value, key, callback) => {
          iziplaySubtitle()
            .get(`/file/${this.props.match.params.file}?lng=${value}`)
            .then(res => {
              res.data.forEach((sub: any, i: number) => {
                subs.push({
                  url: `${process.env.REACT_APP_IZIPLAY_URL_SUBTITLE}/sub/${sub.id}/vtt`,
                  id: sub.id,
                  lang: `${sub.lang} ${i < 10 ? `0${i.toString()}` : i} ${sub.provider}`,
                  langId: sub.langId
                })
              })
              return callback()
            })
        },
        err => {
          if (err) console.log(err)
          this.setState({ subs })
        }
      )
    }
  }

  async ffprobe() {
    const res = await apiHls().get(`/${this.props.match.params.file}/ffprobe`)
    this.setState({ ffprobe: res.data })
    const probeDuration = parseFloat(res.data.format.duration)
    this.probeDuration = probeDuration

    if (!this.listeningPlaying) {
      this.listenPlaying()
    }
  }

  listenPlaying() {
    if (!this.video.current) {
      return
    }
    this.listeningPlaying = true

    console.log('listening events', this.video.current)
    this.video.current?.addEventListener('play', () => {
      stores.ws.rtSend('start', {
        progress: (this.video.current!.currentTime / this.probeDuration) * 100,
        ...this.media
      })
    })

    this.video.current?.addEventListener('pause', () => {
      stores.ws.rtSend('stop', {
        progress: (this.video.current!.currentTime / this.probeDuration) * 100,
        ...this.media
      })
    })
  }

  addUploadedSub(sub: any) {
    this.setState({
      subs: [
        ...this.state.subs,
        {
          url: `${process.env.REACT_APP_IZIPLAY_URL_SUBTITLE}/sub/${sub.id}/vtt`,
          id: sub.id,
          lang: `${sub.lang} internal uploaded`,
          langId: sub.lang
        }
      ]
    })
  }

  render() {
    return (
      <div>
        <MediaPlayer crossorigin src={`${this.state.server}/${this.props.match.params.file}/master.m3u8`} className="vds-video-layout" ref={this.video}>
          <MediaProvider>
            <Track
              src={`${this.state.server}/${this.props.match.params.file}/chapters/master.vtt`}
              kind="chapters"
              default
            />
            {/** TODO: add file subtitles */}
            {this.state.subs.map((subtitle: any) =>
              <Track
                key={subtitle.id}
                src={subtitle.url}
                kind="subtitles"
                label={subtitle.lang}
                lang={subtitle.langcode}
              />
            )}
          </MediaProvider>
          <VideoLayout thumbnails={`${this.state.server}/${this.props.match.params.file}/thumbs/master.vtt?withPosition=true`} />
        </MediaPlayer>

        { (window as any).cast && <button onClick={this.startCast.bind(this)}>Launch cast</button> }
        {this.state.launched ? (
          <div>
            <button onClick={this.castPlayPause.bind(this)}>pause</button>
            <select onChange={this.changeCastSub.bind(this)}>
              {this.state.subs.map((sub: any, i: number) => (
                <option key={i} value={i + 1}>
                  {sub.lang}
                </option>
              ))}
            </select>
            <pre>{JSON.stringify(this.state.castStatus, null, 2)}</pre>
          </div>
        ) : null}
        <div>
          <Stepper activeStep={this.state.step}>
            {this.steps.map(step => (
              <Step key={step}>
                <StepLabel>{step}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {this.state.downloading ? (
            <h4>Download percent: {this.state.downloading.percent}</h4>
          ) : null}
          {this.state.transcoding ? (
            <div>
              <span>Transcoding speed: {this.state.transcoding.speed}</span>
              <br />
              <span>Transcoding time: {this.state.transcoding.time}</span>
            </div>
          ) : null}
        </div>

        <Button onClick={this.openSubtitleModal.bind(this)}>Import subtitle</Button>
        <SubtitleModal
          fileId={this.props.match.params.file}
          open={this.state.isSubtitleModalOpen}
          onClose={this.closeSubtitleModal.bind(this)}
          onUpload={this.addUploadedSub.bind(this)}
        />
      </div>
    )
  }
}

export default observer(HlsPlayer)
