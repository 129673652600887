import { Link } from 'react-router-dom'
import Props from './props'

import utils from '../tools/utils'
import { genres as tmdbGenres } from '../tools/genres'
import './show.css'
import { Chip, Tooltip } from '@material-ui/core'
import { CheckCircleTwoTone } from '@material-ui/icons'

interface ShowProps {
  details: any
  url?: string
  watched?: {
    time: number
    updated: number
  }
}

function Show(props: ShowProps) {
  const { details, url } = props

  const properties = [
    {
      name: 'star',
      value: details.vote_average
    }
  ]
  let formatUrl = details.url || `/${details.title ? 'movies' : 'tvshows'}/${details.id}`
  if (details.file) {
    if (details.file) {
      formatUrl = `/hls/${details.file}?imdb=${details.imdb}&tmdb=${details.id}`
      if (details.id_episode) {
        formatUrl += `&tmdb_episode=${details.id_episode}`
      }
    }
    if (details.season !== null) {
      formatUrl += `&season=${details.season}&episode=${details.episode}`
    }
  }
  formatUrl = url || formatUrl
  let title = details.title || details.name || `Season ${details.season_number}`
  if (details && details.season_number === 0) {
    title = 'Special episode'
  }
  if (details.episode_number) {
    title = `Episode ${details.episode_number}: ${title}`
  }
  if (details && details.title && details.name) {
    title = `${details.title} - ${details.name}`
  }
  if (details && details.adult) {
    title = `🔞 ${title}`
  }

  let { overview } = details
  if (typeof details.episode_count !== 'undefined') {
    overview = `Episodes: ${details.episode_count}`
  }
  const genres: any[] = []
  if (details.genre_ids) {
    details.genre_ids.forEach((genre: any) => {
      Object.keys(tmdbGenres).forEach(g => {
        if (tmdbGenres[g as keyof typeof tmdbGenres] === genre) {
          genres.push(g.charAt(0).toUpperCase() + g.slice(1))
        }
      })
    })
  }

  return (
    <Link to={formatUrl} style={{ color: 'inherit', textDecoration: 'inherit' }}>
      <div className="show">
        <div
          className="img"
          style={
            details.poster_path || details.still_path
              ? {
                  backgroundImage: `url("https://image.tmdb.org/t/p/${
                    details.title ? 'w300' : 'w500'
                  }${details.poster_path || details.still_path}")`
                }
              : {
                  backgroundColor: 'var(--black)',
                  backgroundImage: "url('/img/white-50.png')"
                }
          }
        >
          <div className="filter">
            <img src="/img/play.svg" alt="play" />
          </div>
        </div>
        <div className="infos">
          {details.quality ? <div className="quality">{details.quality}</div> : null}
          {
            props.watched
              ? <div className="watched">
                  <Tooltip title={`Watched ${utils.transformDurateSeconds(props.watched.time)} at ${new Date(props.watched.updated).toLocaleString()}`}>
                    <CheckCircleTwoTone color="primary" />
                  </Tooltip>
                </div>
              : null
          }
          <div className="title">{title}</div>
          <div className="date">
            {details.air_date || details.first_air_date || details.release_date}
          </div>
          {details.season ? (
            <div className="date">{utils.stringTvShow(details.season, details.episode)}</div>
          ) : null}
          <div className="genre">
            {genres.map((genre, index) => (
              <Chip
                key={index}
                style={{ marginRight: '0.5em', marginBottom: '0.5em', cursor: 'pointer' }}
                size="small"
                label={genre}
              />
            ))}
          </div>
          <div className="desc">
            {details.moreDesc ? (
              <div>
                {overview}
                <br />
                <br />
                {details.moreDesc}
              </div>
            ) : (
              overview
            )}
          </div>
          {details.vote_average ? <Props props={properties} /> : null}
        </div>
      </div>
    </Link>
  )
}

export default Show
