class Utils {
  langs = ['fr-FR', 'en-US', 'ru-RU']
  themes = ['light', 'dark', 'auto']

  transformDurate(durate: number) {
    let time = ''

    if (!durate) {
      return ''
    }

    if (durate / 60 >= 1) {
      time = `${Math.floor(durate / 60)} h `
    }
    return `${time}${Math.round(durate % 60)} min`
  }

  transformDurateSeconds(durate: number) {
    return `${this.transformDurate(durate / 60)} ${durate % 60} sec`
  }

  transformDurateMiliseconds(milliseconds: number) {
    const seconds = Math.round(milliseconds / 1000)
    const minutes = Math.floor(seconds / 60)
    const modSeconds = Math.floor(seconds) % 60
    return `${minutes}:${modSeconds < 10 ? '0' : ''}${modSeconds}`
  }

  numberTvshow(season: string, episode: string) {
    const result = {
      season,
      episode
    }
    if (parseInt(season, 10) && parseInt(episode, 10)) {
      if (season.length < 2) {
        result.season = `0${season}`
      }
      if (episode.length < 2) {
        result.episode = `0${episode}`
      }
      return result
    }
    return {
      season: '00',
      episode: '00'
    }
  }

  stringTvShow(season: string, episode: string) {
    const t = this.numberTvshow(season.toString(), episode.toString())
    return `S${t.season}E${t.episode}`
  }

  isJapaneseCharacters(name: string) {
    const regex =
      /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g

    return regex.test(name)
  }
}

export default new Utils()
