import { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { NavLink } from 'react-router-dom'
import { tmdb, iziplayAuth, iziplaySocial } from '../tools/api'
import { stores } from '../tools/store'
import { isErrorResponse } from '../tools/typeGuards'

import './admin.css'

type NotificationType = 'info' | 'success' | 'error'

export function Admin() {
  const [users, setUsers] = useState<Iziplay.User[]>([])
  const [clients, setClients] = useState<Iziplay.Client[]>([])
  const [addUserModal, setAddUserModal] = useState(false)
  const [editUserModal, setEditUserModal] = useState(false)
  const [notificationType, setNotificationType] = useState<NotificationType>('info')
  const [notificationText, setNotificationText] = useState('')
  const [editUser, setEditUser] = useState<Iziplay.User>()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [settings, setSettings] = useState('')

  async function getUsers() {
    try {
      const res = await iziplayAuth().get<Iziplay.UsersResponse>('/users/')
      setUsers(res.data.users)
    } catch (error) {
      if (!isErrorResponse(error) || !error.response) return
      stores.notification.displayNotification(error.response.data.err, 'error')
    }
  }

  async function getConnected() {
    try {
      const res = await iziplaySocial().get<Iziplay.ClientsResponse>('/wsConnected/')
      setClients(res.data.clients)
    } catch (error) {
      if (!isErrorResponse(error) || !error.response) return
      stores.notification.displayNotification(error.response.data.err, 'error')
    }
  }

  useEffect(() => {
    getUsers()
    getConnected()
  }, [])

  async function openEditUserModal(user: Iziplay.User) {
    setEditUserModal(true)
    setEditUser(user)
    setUsername(user.username)
    setEmail(user.mail)

    try {
      const res = await iziplaySocial().get<Iziplay.SettingsReponse>(`/settings/${user.id}`)
      setSettings(JSON.stringify(res.data, null, 2))
    } catch (err) {}
  }

  async function addUser() {
    try {
      await iziplayAuth().post('/users/', {
        username,
        mail: email,
        password
      })
      stores.notification.displayNotification('User added', 'success')
      getUsers()
      getConnected()
    } catch (error) {
      if (!isErrorResponse(error) || !error.response) return
      stores.notification.displayNotification(error.response.data.err, 'error')
    }
    setAddUserModal(false)
  }

  async function updateUser() {
    try {
      await iziplayAuth().put(`/users/${editUser?.id}`, {
        username: username === editUser?.username ? '' : username,
        mail: email === editUser?.mail ? '' : email
      })
      stores.notification.displayNotification('User updated', 'success')
      getUsers()
      getConnected()
    } catch (error) {
      if (!isErrorResponse(error) || !error.response) return
      stores.notification.displayNotification(error.response.data.err, 'error')
    }
    setAddUserModal(false)
  }

  function randomPass() {
    const words = ['pierre', 'papier', 'ciseau', 'soleil', 'bite', 'jambon', 'poulet']
    const chars = ['!', '?', '#', '*', '.']
    let pass = words[Math.floor(Math.random() * words.length)]
    pass += chars[Math.floor(Math.random() * chars.length)]
    pass += Math.floor(Math.random() * 90) + 10
    setPassword(pass)
    return pass
  }

  async function reinitUserPassword() {
    const pass = randomPass()

    try {
      await iziplayAuth().put(`/users/${editUser?.id}`, {
        password: pass
      })
      stores.notification.displayNotification('User updated', 'success')
      window.alert(`New password is: ${pass}`)
    } catch (error) {
      if (!isErrorResponse(error) || !error.response) return
      stores.notification.displayNotification(error.response.data.err, 'error')
    }
    setAddUserModal(false)
  }

  async function updateUserSettings() {
    let data = {}
    try {
      data = JSON.parse(settings)
      await iziplaySocial().post(`/settings/${editUser?.id}`, data)
      stores.notification.displayNotification('', 'success')
    } catch (error) {
      if (!isErrorResponse(error) || !error.response) return
      stores.notification.displayNotification(error.response.data.err, 'error')
    }
  }

  async function deleteUser() {
    if (!confirm(`Are u sure to delete '${editUser?.id}'?`)) {
      return
    }
    try {
      await iziplayAuth().delete(`/users/${editUser?.id}`)
      stores.notification.displayNotification('', 'success')
    } catch (e) {
      stores.notification.displayNotification('', 'error')
    }

    setAddUserModal(false)
    getUsers()
  }

  async function sendNotif() {
    try {
      await iziplaySocial().post('/immediateNotification', {
        text: notificationText,
        type: notificationType
      })
    } catch (error) {
      if (!isErrorResponse(error) || !error.response) return
      stores.notification.displayNotification(error.response.data.err, 'error')
    }
  }

  async function openLink(client: Iziplay.Client) {
    if (client.viewing && client.viewing.imdb) {
      try {
        const res = await tmdb.get(`/find/${client.viewing.imdb}`, {
          params: {
            external_source: 'imdb_id'
          }
        })
        if (client.viewing.type === 'tvshow') {
          window.open(`https://www.themoviedb.org/tv/${res.data.tv_results[0].id}`)
        } else if (client.viewing.type === 'movie') {
          window.open(`https://www.themoviedb.org/movie/${res.data.movie_results[0].id}`)
        }
      } catch (error) {
        if (!isErrorResponse(error) || !error.response) return
        stores.notification.displayNotification(error.response.data.err, 'error')
      }
    }
  }

  return (
    <div className="admin scrollable">
      <ReactModal
        isOpen={addUserModal}
        onRequestClose={() => setAddUserModal(false)}
        className="modal admin-modal"
        contentLabel="Modal"
      >
        <input type="text" placeholder="Username" onChange={e => setUsername(e.target.value)} />
        <br />
        <input type="email" placeholder="Mail" onChange={e => setEmail(e.target.value)} />
        <br />
        <input
          type="text"
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
          value={password}
        />
        <button onClick={() => randomPass()}>⚷</button>
        <br />
        <br />
        <button onClick={() => addUser()} style={{ marginRight: '1em' }}>
          Add user
        </button>
        <button onClick={() => setAddUserModal(false)}>Close Modal</button>
      </ReactModal>

      <ReactModal
        isOpen={editUserModal}
        onRequestClose={() => setEditUserModal(false)}
        className="modal admin-modal"
        contentLabel="Modal"
      >
        <input
          type="text"
          placeholder="Username"
          onChange={e => setUsername(e.target.value)}
          value={username}
        />
        <br />
        <input
          type="email"
          placeholder="Mail"
          onChange={e => setEmail(e.target.value)}
          value={email}
        />
        <br />

        <textarea
          style={{
            width: '100%',
            height: '10em',
            whiteSpace: 'pre-wrap',
            wordWrap: 'normal',
            fontFamily: 'monospace'
          }}
          onChange={e => setSettings(e.target.value)}
          value={settings}
        />
        <br />
        <br />

        <button onClick={() => reinitUserPassword()} style={{ marginRight: '1em' }}>
          Reinit Password
        </button>
        <button onClick={() => updateUser()} style={{ marginRight: '1em' }}>
          Edit user
        </button>
        <button onClick={() => updateUserSettings()} style={{ marginRight: '1em' }}>
          Edit settings
        </button>
        <button onClick={() => deleteUser()} style={{ marginRight: '1em' }}>
          Delete user
        </button>
        <button onClick={() => setEditUserModal(false)}>Close Modal</button>
      </ReactModal>

      <div className="users box">
        <div className="title">
          Users <button onClick={() => getUsers()}>Reload</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Mail</th>
              <th>Role</th>
              <th>Manager</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id} onClick={() => openEditUserModal(user)}>
                <td>{user.id}</td>
                <td>{user.username}</td>
                <td>{user.mail}</td>
                <td>{user.role}</td>
                <td>{user.managerName}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={() => setAddUserModal(true)}>Add user</button>
      </div>

      <div className="users box">
        <div className="title">
          Connected <button onClick={() => getConnected()}>Reload</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Device</th>
              <th>Type</th>
              <th>IMDB</th>
              <th>Season</th>
              <th>Episode</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, i) => (
              <tr key={`connected_${i}_${client.id}`}>
                <td>{client.id}</td>
                <td>{client.username}</td>
                <td>{client.viewing && client.viewing.device}</td>
                <td>{client.viewing && client.viewing.type}</td>
                <td className="imdbLink" onClick={() => openLink(client)}>
                  {client.viewing && client.viewing.imdb}
                </td>
                <td>{client.viewing && client.viewing.season}</td>
                <td>{client.viewing && client.viewing.episode}</td>
                <td>
                  {client.viewing &&
                    `${Math.floor(client.viewing.time / 3600)}:${Math.floor(
                      (client.viewing.time / 60) % 60
                    )}:${Math.floor(client.viewing.time % 60)}`}
                </td>
                <td>
                  {client.viewing && (
                    <NavLink to={`/hls/${client.viewing.file}`}>View file</NavLink>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="box admin_notif">
        <div className="title">Push notification</div>
        <p className="desc">
          Send notification to all connected users with WS (including chromecasts)
        </p>
        <textarea
          placeholder="Text of notification"
          onChange={e => setNotificationText(e.target.value)}
        />
        <br />
        <select
          onChange={e => setNotificationType(e.target.value as NotificationType)}
          value={notificationType}
        >
          <option value="info">Info</option>
          <option value="success">Success</option>
          <option value="error">Error</option>
        </select>
        <br />
        <button onClick={() => sendNotif()}>Send</button>
      </div>
    </div>
  )
}
