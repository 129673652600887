import { Card, CardMedia, IconButton, Slider, Typography } from '@material-ui/core'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import { PauseIcon, PlaylistIcon } from '@vidstack/react/icons'
import { useAudioPlayer } from './provider'
import { Link } from 'react-router-dom'

export const AudioPlayer = () => {
  const { currentTrack, play, pause, back, next, setTrackPosition, playing, percent } = useAudioPlayer()
  return (
    <Card style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardMedia
            style={{ height: 40, width: 40, marginBottom: 10 }}
            image={currentTrack && `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${currentTrack?.artist.id}/release/${currentTrack?.release.id}/artwork?size=80`}
            title={currentTrack && `Cover of ${currentTrack.release.name}`}
          />
          <Link to="/music/playlist">
            <IconButton aria-label="playlist">
              <PlaylistIcon style={{ height: 20, width: 20 }} />
            </IconButton>
          </Link>
        </div>
        <Typography variant="subtitle1">
          {currentTrack?.name ?? '-'}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {currentTrack?.artist.name ?? '-'}
        </Typography>
        <Slider
          value={percent * 100}
          onChange={(e, value) => {
            if (Array.isArray(value)) {
              return
            }
            setTrackPosition(value / 100)
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '4px'
          }}
        >
          <IconButton
            aria-label="previous"
            onClick={() => {
              back()
            }}
          >
            <SkipPreviousIcon />
          </IconButton>
          <IconButton
            aria-label="play/pause"
            onClick={() => {
              if (playing) {
                return pause()
              }
              play()
            }}
          >
            {playing ? <PauseIcon style={{ height: 38, width: 38 }} /> : <PlayArrowIcon style={{ height: 38, width: 38 }} />}
          </IconButton>
          <IconButton
            aria-label="next"
            onClick={() => {
              next()
            }}
          >
            <SkipNextIcon />
          </IconButton>
        </div>
      </div>
    </Card>
  )
}
