import axios from 'axios'
import { useEffect, useState } from 'react'
import Spinner from '../../components/spinner'
import { Button, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { Artist, Track, useAudioPlayer } from '../../components/audioPlayer/provider'
import { PauseIcon, PlayIcon } from '@vidstack/react/icons'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { PlaylistAdd } from '@material-ui/icons'
import Utils from '../../tools/utils'
import { useHistory } from 'react-router-dom'

interface MusicReleaseProps {
  match: any
}

export function MusicRelease({ match }: MusicReleaseProps) {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [release, setRelease] = useState<any>()
  const [artist, setArtist] = useState<Artist | undefined>()
  const [media, setMedia] = useState<any[]>([])
  const [tracks, setTracks] = useState<Track[]>([])

  const { setPlaylistPlayId, currentTrack, pause, play, playId, playing, playlist, addToPlaylist } = useAudioPlayer()

  let interval: NodeJS.Timeout | null = null

  const prepare = async () => {
    const res = await axios.request({
      method: 'POST',
      url: `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/prepare`,
      data: {
        type: 'text',
        artist: match.params.artist,
        release: match.params.release
      }
    })
    setRelease(res.data.release)
    setArtist(res.data.artist)
    setMedia(res.data.media)

    const mTracks: Track[] = []
    for (const medium of res.data.media) {
      for (const track of medium.tracks.sort((a: any, b: any) => a.position - b.position)) {
        mTracks.push({
          id: track.id,
          name: track.name,
          artist: {
            id: res.data.artist.id,
            name: res.data.artist.name
          },
          release: {
            id: res.data.release.id,
            name: res.data.release.name
          },
          medium: medium.position,
          position: track.position,
          url: `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${res.data.artist.id}/release/${res.data.release.id}/medium/${medium.id}/position/${track.position}/file`,
          status: track.status,
          duration: track.duration
        })
      }
    }
    setTracks(mTracks)
    setIsLoading(false)

    interval = setInterval(async () => {
      const i = await axios.request({
        method: 'GET',
        url: `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${res.data.artist.id}/release/${res.data.release.id}?date=${Date.now()}`
      })
      if (i.data.release.media.every((medium: any) => medium.tracks.every((track: any) => track.status === 'COMPLETED' || track.status === 'UNKNOWN'))) {
        clearInterval(interval!)
      }
      const nTracks: Track[] = []
      for (const medium of i.data.release.media) {
        for (const track of medium.tracks.sort((a: any, b: any) => a.position - b.position)) {
          nTracks.push({
            id: track.id,
            name: track.name,
            artist: {
              id: res.data.artist.id,
              name: res.data.artist.name
            },
            release: {
              id: res.data.release.id,
              name: res.data.release.name
            },
            medium: medium.position,
            position: track.position,
            url: `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${res.data.artist.id}/release/${i.data.release.id}/medium/${medium.id}/position/${track.position}/file`,
            status: track.status,
            duration: track.duration
          })
        }
      }
      setTracks(nTracks)
    }, 2 * 1000)
  }

  useEffect(() => {
    prepare()

    return () => {
      if (!interval) {
        return
      }
      clearInterval(interval)
    }
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="scrollable" style={{ display: 'flex', flexDirection: 'column', padding: '12px' }}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px', margin: '14px' }}>
          <img
            src={`${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${artist!.id}/release/${release.id}/artwork?size=400`}
            style={{ width: 300, height: 300, borderRadius: '10px' }}
          />
          <div style={{ display: 'flex', padding: '40px 20px 0px 20px', flexDirection: 'column' }}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>{release.name}</Typography>
            <Link
              onClick={() => {
                history.push(`/music/${artist?.id}`)
              }}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant="h6">{artist?.name}</Typography>
            </Link>
            <div style={{ flexGrow: '1' }} />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PlayArrowIcon />}
                onClick={() => {
                  setPlaylistPlayId(tracks, tracks[0].id)
                }}
              >
                Play
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<PlaylistAdd />}
                onClick={() => {
                  addToPlaylist(tracks)
                }}
              >
                Add to playlist
              </Button>
            </div>
          </div>
        </div>
        <List style={{ flex: 1, overflowY: 'auto' }}>
          {
            tracks.map(track => (
              <ListItem
                key={track.id}
                button
                onClick={() => {
                  if (currentTrack?.id === track.id && playing) {
                    return pause()
                  }
                  if (currentTrack?.id === track.id && !playing) {
                    return play()
                  }

                  const index = playlist.findIndex(t => t.id === track.id)
                  if (index === -1) {
                    setPlaylistPlayId(tracks, track.id)
                    return
                  }

                  playId(track.id)
                }}
              >
                <ListItemText primary={`${media.length > 1 ? `CD${track.medium} ` : ''}${track.position}. ${track.name}`} secondary={track.status} />
                <ListItemText>
                  <Typography style={{ textAlign: 'end' }} variant="subtitle2">
                    {Utils.transformDurateMiliseconds(track.duration)}
                  </Typography>
                </ListItemText>
                <ListItemIcon>{currentTrack?.id === track.id && (playing ? <PlayIcon /> : <PauseIcon />)}</ListItemIcon>
              </ListItem>
            ))
          }
        </List>
      </div>
    </div>
  )
}
