const Query = {
  parse(params: string) {
    const splitParams = params.slice(1, params.length).split('&')
    const obj: {
      [x: string]: string
    } = {}
    splitParams.forEach(param => {
      const [, query] = param.split('=')

      obj[param.split('=')[0]] = query
    })

    return obj
  }
}

export default Query
