import axios from 'axios'
import { useEffect, useState } from 'react'
import Spinner from '../../components/spinner'
import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@material-ui/core'
import { Artist, Track, useAudioPlayer } from '../../components/audioPlayer/provider'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { useHistory } from 'react-router-dom'

interface MusicArtistProps {
  match: any
}

export function MusicArtist({ match }: MusicArtistProps) {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [artist, setArtist] = useState<Artist | undefined>()

  const { setPlaylistPlayId } = useAudioPlayer()

  const fetchArtist = async () => {
    const res = await axios.request({
      method: 'GET',
      url: `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${match.params.artist}`
    })
    setArtist(res.data.artist)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchArtist()
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  if (!artist) {
    return <p>Cannot get artist</p>
  }

  return (
    <div className="scrollable" style={{ display: 'flex', flexDirection: 'column', padding: '12px' }}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px', margin: '14px' }}>
          <img
            src={`${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${artist!.id}/artwork?size=400`}
            style={{ width: 300, height: 300, borderRadius: '150px' }}
          />
          <div style={{ display: 'flex', padding: '40px 20px 0px 20px', flexDirection: 'column' }}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>{artist.name}</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', paddingTop: '10px' }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PlayArrowIcon />}
                onClick={() => {
                  const tracks = artist.releases?.map(release => release.media?.map(medium => medium.tracks.map(track => ({ ...track, artist, release, url: `${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${artist.id}/release/${release.id}/medium/${medium.id}/position/${track.position}/file` }))).flat()).flat()
                    ?.filter(track => Boolean(track)) as Track[]
                  if (tracks.length > 0) {
                    console.log('setplay', tracks, tracks[0].id)
                    setPlaylistPlayId(tracks, tracks[0].id)
                  }
                }}
              >
                Play everything
              </Button>
            </div>
          </div>
        </div>
        <Grid container spacing={1}>
          {artist.releases?.map(release => (
            <Grid key={release.id} item xs={12} sm={6} md={4} lg={2}>
              <Card variant="outlined">
                <CardActionArea
                  onClick={() => {
                    history.push(`/music/${artist.name}/${release.name}`)
                  }}
                >
                  <CardMedia
                    style={{ height: '180px' }}
                    image={`${(process.env.REACT_APP_IZIPLAY_URL_MUSIC as string)}/artist/${artist.id}/release/${release.id}/artwork?size=360`}
                    title={`Cover of ${release.name}`}
                  />
                  <CardContent>
                    <Typography variant="body1" component="h2" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {release.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}
