import { useEffect } from 'react'
import NProgress from 'nprogress'

import './header.css'

import { stores } from '../tools/store'
import { observer } from 'mobx-react'
import { Visibility, VisibilityOff } from '@material-ui/icons'

interface HeaderProps {
  title: string
}

function Header(props: HeaderProps) {
  const { title } = props

  useEffect(() => {
    // @ts-ignore
    window.__onGCastApiAvailable = (isAvailable: any) => {
      if (isAvailable) {
        // @ts-ignore
        window.cast.framework.CastContext.getInstance().setOptions({
          receiverApplicationId: '80EB6972'
        })
        // @ts-ignore
        const player = new window.cast.framework.RemotePlayer()
        // @ts-ignore
        const controller = new window.cast.framework.RemotePlayerController(player)

        controller.addEventListener(
          // @ts-ignore
          window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
          (event: any) => {
            if (event.field === 'isConnected' && event.value) {
              // @ts-ignore
              const session = window.cast.framework.CastContext.getInstance().getCurrentSession()
              if (session) {
                session.sendMessage(
                  'urn:x-cast:tv.iziplay.chromecast',
                  {
                    type: 'auth',
                    data: {
                      token: global.localStorage.token,
                      privateMode: stores.privateMode.get()
                    }
                  },
                  () => console.log('good'),
                  () => console.log('bad')
                )
              }
            }
          }
        )
      }
    }
  }, [])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'
    script.async = true
    document.body.appendChild(script)

    NProgress.configure({
      parent: '.nprogress-container',
      template: '<div class="bar" role="bar"><div class="peg"></div></div>',
      showSpinner: false,
      trickle: false
    })
  }, [])

  function menu() {
    stores.mobileSidebar.setMobileSidebar(!stores.mobileSidebar.get())
    console.log('Sidebar', stores.mobileSidebar.get())
  }

  function privateMode() {
    stores.privateMode.setPrivateMode(!stores.privateMode.get())
    stores.ws.connectWs()
  }

  return (
    <div className="header">
      <div className="nprogress-container" />
      <div className="bar">
        <button className="menu-icon" onClick={menu}>
          Menu
        </button>
        <div className="name">{title}</div>
        <div className="icons">
          <button onClick={privateMode}>
            {stores.privateMode.get() ? <VisibilityOff /> : <Visibility />}
          </button>
          {navigator.userAgent.includes('Chrome') && (
            <button
              className="cast-button"
              is="google-cast-button"
              style={{
                margin: '6px',
                width: '30px',
                height: '30px',
                float: 'right'
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(Header)
