import { ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { debounce } from 'lodash'
import './searchbar.css'

export const SearchBar = observer(() => {
  const history = useHistory()

  const search = debounce(value => {
    if (!value || value === '') return
    history.push(`/search?query=${encodeURIComponent(value)}`)
  }, 500)

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    search(event.target.value)
  }

  return (
    <div className="search">
      <div className="input">
        <input
          id="searchInput"
          type="text"
          onChange={onChange}
          placeholder="Search movie, tv show or people ..."
        />
      </div>
    </div>
  )
})
