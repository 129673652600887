import { useEffect, useState, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'

import { observer } from 'mobx-react'
import Button from '@material-ui/core/Button'

import { isStringTheme, isStringLanguage } from '../tools/typeGuards'
import { iziplayAuth } from '../tools/api'
import { stores } from '../tools/store'
import languages from '../tools/languages.json'
import utils from '../tools/utils'

import './settings.css'

interface SettingsState {
  colorTheme: string
  lang: Iziplay.Lang
  username: string
  mail: string
  oldPwd: string
  newPwd: string
  newPwd2: string
  providers: any[]
}

export const Settings = observer(() => {
  const history = useHistory()
  const [state, setState] = useState<SettingsState>({
    colorTheme: global.localStorage.getItem('color') || '#7E94F7',
    lang: stores.lang.get(),
    username: '',
    mail: '',
    oldPwd: '',
    newPwd: '',
    newPwd2: '',
    providers: []
  })

  function handle(
    field: Exclude<keyof SettingsState, 'providers'>,
    e: ChangeEvent<HTMLInputElement>
  ) {
    const obj: Partial<SettingsState> = {}
    if (field === 'lang') {
      if (isStringLanguage(e.target.value)) {
        obj[field] = e.target.value
      }
    } else {
      obj[field] = e.target.value
    }

    setState(oldState => ({
      ...oldState,
      ...obj
    }))
  }

  function changeLang(e: ChangeEvent<HTMLSelectElement>) {
    const newLang = e.target.value

    if (isStringLanguage(newLang)) {
      setState(oldState => ({
        ...oldState,
        lang: newLang
      }))
      stores.lang.setLang(newLang)
      stores.settings.settings.lang = newLang
      stores.settings.sendSetting('lang')

      stores.movies.resetMovies()
      stores.tvshows.resetTvshows()
      stores.downloaded.resetDownloaded()
      stores.myhistory.resetMyHistory()
    }
  }

  function updateData() {
    iziplayAuth()
      .put('/me/', {
        username: state.username,
        mail: state.mail
      })
      .then(res => {
        stores.notification.displayNotification(res.data.msg, 'success')
        stores.user.fetchUser()
        setState(oldState => ({
          ...oldState,
          username: '',
          mail: ''
        }))
      })
      .catch(err => {
        if (err.response) {
          stores.notification.displayNotification(err.response.data.err, 'error')
        } else {
          stores.notification.displayNotification('An error occured, sorry!', 'error')
        }
      })
  }

  function password() {
    if (state.newPwd !== state.newPwd2) {
      stores.notification.displayNotification('New password not equal', 'error')
      return
    }
    iziplayAuth()
      .put('/me/password', {
        oldPassword: state.oldPwd,
        password: state.newPwd
      })
      .then(res => {
        setState(oldState => ({
          ...oldState,
          oldPwd: '',
          newPwd: '',
          newPwd2: ''
        }))

        stores.notification.displayNotification('Your new password is so... cool!', 'success')
      })
      .catch(err => {
        stores.notification.displayNotification(err.response.data.err, 'error')
      })
  }

  function disconnect() {
    iziplayAuth()
      .post('/me/logout')
      .then(res => {
        global.localStorage.removeItem('token')
        history.push('/')
      })
      .catch(err => {
        stores.notification.displayNotification(err.response.data.err, 'error')
      })
  }

  function theme(e: ChangeEvent<HTMLInputElement>) {
    if (isStringTheme(e.target.value)) {
      stores.theme.saveTheme(e.target.value)
    }
  }

  function changeColor(color: SettingsState['colorTheme']) {
    setState(oldState => ({
      ...oldState,
      colorTheme: color
    }))

    stores.theme.changeInterfaceColor(color)
  }

  function changeProviders(e: ChangeEvent<HTMLInputElement>) {
    stores.settings.settings.providers[e.target.name] = !stores.settings.settings.providers[
      e.target.name
    ]
    stores.settings.sendSetting('providers')
  }

  function changeSubtitles(e: ChangeEvent<HTMLInputElement>, code: typeof languages[0]['code']) {
    const pos = stores.settings.settings.subtitles.indexOf(code)
    if (pos === -1) {
      stores.settings.settings.subtitles.push(code)
    } else {
      stores.settings.settings.subtitles.splice(pos, 1)
    }
    stores.settings.sendSetting('subtitles')
  }

  useEffect(() => {
    stores.currentPath.setCurrentPath([])
  }, [])

  return (
    <div className="settings scrollable">
      <div className="box">
        <div className="title">Profile</div>
        <div className="element">
          To modify your avatar, go to{' '}
          <a href="https://fr.gravatar.com/" target="blank_">
            Gravatar
          </a>
          .<br />
          <input
            type="text"
            placeholder="Username"
            value={state.username}
            onChange={e => handle('username', e)}
          />
          <br />
          <input
            type="mail"
            placeholder="Mail"
            value={state.mail}
            onChange={e => handle('mail', e)}
          />{' '}
          <br />
          <button onClick={updateData}>Save</button>
        </div>
      </div>
      <div className="box">
        <div className="title">Theme</div>
        <div className="element">
          <div>
            <input
              type="radio"
              name="theme"
              value="light"
              checked={stores.theme.get() === 'light'}
              onChange={e => theme(e)}
            />{' '}
            Light <br />
            <input
              type="radio"
              name="theme"
              value="dark"
              checked={stores.theme.get() === 'dark'}
              onChange={e => theme(e)}
            />{' '}
            Dark <br />
            <input
              type="radio"
              name="theme"
              value="auto"
              checked={stores.theme.get() === 'auto'}
              onChange={e => theme(e)}
            />{' '}
            Auto (switch to dark when night)
            <br />
            <input
              type="color"
              name="colorTheme"
              value={state.colorTheme}
              onChange={e => changeColor(e.target.value)}
            />{' '}
            Color
          </div>
        </div>
      </div>
      <div className="box">
        <div className="title">Providers</div>
        <div className="element">
          <div>
            {Object.keys(stores.settings.get().providers).map(prov => (
              <div key={prov}>
                <input
                  type="checkbox"
                  key={prov}
                  name={prov}
                  onChange={e => changeProviders(e)}
                  defaultChecked={stores.settings.get().providers[prov]}
                />{' '}
                {prov}{' '}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="box">
        <div className="title">Subtitles</div>
        <div className="element">
          <div>
            {languages.map(lang => (
              <div key={`sub_${lang.code}`}>
                <input
                  type="checkbox"
                  key={lang.code}
                  name={lang.code}
                  onChange={e => changeSubtitles(e, lang.code)}
                  defaultChecked={stores.settings.get().subtitles.indexOf(lang.code) !== -1}
                />
                {`${lang.name} (${lang.local})`}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="box">
        <div className="title">Language</div>
        <div className="element">
          <div>
            <select value={state.lang} onChange={e => changeLang(e)}>
              {utils.langs.map(lang => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="box">
        <div className="title">Third-party services</div>
        <button
          className="oauth-button tvtime-button"
          onClick={() => {
            window.open('https://www.tvtime.com/oauth/authorize?client_id=H600Xy4crb_WQgB1WGtV')
          }}
        >
          <img src="/img/services/tvtime.jpg" />
          {stores.user.get().tvTime ? 'TVTime already connected' : 'Connect TVTime'}
        </button>
        <br />
        <button
          className="oauth-button trakt-button"
          onClick={() => {
            window.open(
              'https://trakt.tv/oauth/authorize?client_id=806f276c7e5e5020be88962113493e219382a31184d2ed54a491cdc46a41c7df&redirect_uri=https%3A%2F%2Fauth.iziplay.fruitice.fr%2Foauth%2Ftrakt&response_type=code'
            )
          }}
        >
          <img src="/img/services/trakt.png" />
          {stores.user.get().trakt ? 'Trakt already connected' : 'Connect Trakt'}
        </button>
      </div>
      <div className="box">
        <div className="title">Password</div>
        <div className="element">
          <input
            type="password"
            placeholder="Old password"
            value={state.oldPwd}
            onChange={e => handle('oldPwd', e)}
          />
          <br />
          <input
            type="password"
            placeholder="New password"
            value={state.newPwd}
            onChange={e => handle('newPwd', e)}
          />
          <br />
          <input
            type="password"
            placeholder="New password again"
            value={state.newPwd2}
            onChange={e => handle('newPwd2', e)}
          />
          <br />
          <button onClick={() => password()}>Save</button>
        </div>
      </div>
      <div className="box">
        <div className="title">Connection</div>
        <Button variant="contained" color="primary" onClick={disconnect}>
          Deconnect
        </Button>
      </div>
    </div>
  )
})
