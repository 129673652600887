interface TableProps {
  data: any
}

export function Table(props: TableProps) {
  const { data } = props

  return (
    <table>
      <tr>
        <th>Variable</th>
        <th>Value</th>
      </tr>
      {Object.keys(data).map((v, index) => (
        <tr key={index}>
          <td>{v}</td>
          <td>{data[v]}</td>
        </tr>
      ))}
    </table>
  )
}
