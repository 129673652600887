import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { useAudioPlayer } from '../../components/audioPlayer/provider'
import { PlayIcon } from '@vidstack/react/icons'
import { DeleteOutline } from '@material-ui/icons'
import { Link } from 'react-router-dom'

export function Playlist() {
  const { playId, currentTrack, playlist, removeFromPlaylist } = useAudioPlayer()

  if (playlist.length === 0) {
    return <p>No tracks added to your playlist, go to <Link to="/music">Search page</Link> to find the best 🎵</p>
  }

  return (
    <List style={{ flex: 1, overflowY: 'auto' }}>
      {
        playlist.map(track => (
          <ListItem
            key={track.id}
            button
            onClick={() => {
              playId(track.id)
            }}
          >
            <ListItemText primary={track.name} secondary={track.status} />
            {currentTrack?.id === track.id && <ListItemIcon><PlayIcon /></ListItemIcon>}
            <ListItemSecondaryAction onClick={() => removeFromPlaylist(track.id)}>
              <IconButton aria-label="remove from playlist">
                <DeleteOutline style={{ height: 20, width: 20 }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))
      }
    </List>
  )
}
